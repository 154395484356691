import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Validation from "../views/Validation.vue";
import MintStatus from "../views/MintStatus.vue";
import NFTStatus from "../views/NFTStatus.vue";
import Edit from "../views/Edit.vue";
import Subscriber from "../views/Subscriber";
import Scanner from "../views/Scanner";
import UpdatePMG from "../views/UpdatePMG.vue";
import Runner from "../views/Runner.vue";
import RunnerRecord from "../views/RunnerRecord.vue";
import Dealer from "../views/Dealer.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    // meta: {
    //   requiresAuth: true,
    // },
  },
  {
    path: "/mintstatus",
    name: "MintStatus",
    component: MintStatus,
    // meta: {
    //   requiresAuth: true,
    // },
  },
  {
    path: "/nftstatus",
    name: "NFTStatus",
    component: NFTStatus,
    // meta: {
    //   requiresAuth: true,
    // },
  },
  {
    path: "/validation",
    name: "Validation",
    component: Validation,
    // meta: {
    //   requiresAuth: true,
    // },
  },
  {
    path: "/edit",
    name: "Edit",
    component: Edit,
    // meta: {
    //   requiresAuth: true,
    // },
  },
  {
    path: "/subscriber",
    name: "Subscriber",
    component: Subscriber,
    // meta: {
    //   requiresAuth: true,
    // },
  },
  {
    path: "/scanner",
    name: "Scanner",
    component: Scanner,
    // meta: {
    //   requiresAuth: false,
    // },
  },
  {
    path: "/update",
    name: "UpdatePMG",
    component: UpdatePMG,
    // meta: {
    //   requiresAuth: true,
    // },
  },
  {
    path: "/runner",
    name: "Runner",
    component: Runner,
    // meta: {
    //   requiresAuth: true,
    // },
  },
  {
    path: "/record",
    name: "RunnerRecord",
    component: RunnerRecord,
    // meta: {
    //   requiresAuth: true,
    // },
  },
  {
    path: "/dealer",
    name: "Dealer",
    component: Dealer,
  },
  { path: "*", redirect: "/" }, // catch all use case
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, _, next) => {
//   // instead of having to check every route record with
//   // to.matched.some(record => record.meta.requiresAuth)
//   let address = localStorage.getItem("address");
//   if (to.meta.requiresAuth && !address) {
//     // console.log(to.fullPath);
//     // this route requires auth, check if logged in
//     // if not, redirect to login page.

//     return next({ name: "Scanner" });
//   }

//   if (!to.meta.requiresAuth && address) {
//     return next({ name: "Home" });
//   }

//   return next();
// });

export default router;
