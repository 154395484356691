<template>
  <div class="body">
    <div class="body-container">
      <!-- <div class="spinner text-center" v-if="!loading">
        <b-spinner
          style="width: 3rem; height: 3rem"
          variant="primary"
        ></b-spinner>
      </div> -->
      <div class="title">
        <h1>{{ $t("Validation") }}</h1>
      </div>
      <hr />
      <div class="form-data">
        <div class="image-section">
          <div>
            <img v-if="fronturl" :src="fronturl" class="image-display" />
            <button class="uploadImage button">
              <input type="file" @change="onFrontFileChange" />
              {{ $t("Upload Front Image") }}
            </button>
            <div class="error">{{ frontImageError && frontImageError }}</div>
          </div>
          <div>
            <img v-if="backurl" :src="backurl" class="image-display" />
            <button class="uploadImage button">
              <input type="file" @change="onBackFileChange" />
              {{ $t("Upload Back Image") }}
            </button>
            <div class="error">{{ backImageError && backImageError }}</div>
          </div>
        </div>
        <hr />
        <div class="form-details">
          <form @submit.prevent="confirmMessage()">
            <div class="form-line">
              <label for="type">{{ $t("Type") }} : </label>
              <select v-model="type" required>
                <option value="" selected disabled>
                  {{ $t("Choose Type") }}
                </option>
                <option
                  v-for="type in typeList"
                  :value="type.name"
                  :key="type.id"
                >
                  {{ type.name }}
                </option>
                <!-- <option value="Paper Money">
                  {{ $t("Paper Money") }}
                </option>
                <option value="Stamps">{{ $t("Stamps") }}</option>
                <option value="Postcards">{{ $t("Postcards") }}</option>
                <option value="Testnotes">{{ $t("Testnotes") }}</option>
                <option value="Tickets, Shares & Coupons">
                  {{ $t("Tickets, Shares & Coupons") }}
                </option> -->
              </select>
            </div>
            <hr />
            <div class="form-line">
              <label for="category">{{ $t("Category") }} : </label>
              <select v-model="category" required>
                <option value="" selected disabled>
                  {{ $t("Choose Category") }}
                </option>
                <option
                  v-for="category in categoryList"
                  :value="category.name"
                  :key="category.id"
                >
                  {{ category.name }}
                </option>
              </select>
            </div>
            <hr />
            <div class="form-line">
              <label for="pioneerAddress">{{ $t("Pioneer Address") }} : </label>
              <textarea
                v-model="pioneerAddress"
                type="text"
                id="pioneerAddress"
                required
              />
            </div>
            <hr />
            <div class="form-line">
              <label for="pmgCert">{{ $t("PMG Cert") }} # : </label>
              <div>
                <textarea v-model="pmgCert" type="text" id="pmgCert" required />
                <div class="checkPMG button" @click="checkPrePMG">
                  Check PrePMG
                </div>
              </div>
            </div>
            <hr />
            <div class="form-line">
              <label for="noteDesc">{{ $t("Note Description") }} : </label>
              <textarea v-model="noteDesc" type="text" id="noteDesc" />
            </div>
            <hr />
            <div class="form-line">
              <label for="note">{{ $t("Note") }} # : </label>
              <textarea v-model="note" type="text" id="note" />
            </div>
            <hr />
            <div class="form-line">
              <label for="serial">{{ $t("Serial") }} # : </label>
              <textarea v-model="serial" type="text" id="serial" />
            </div>
            <hr />
            <div class="form-line">
              <label for="country">{{ $t("Country") }} : </label>
              <textarea v-model="country" type="text" id="country" />
            </div>
            <hr />
            <div class="form-line">
              <label for="year">{{ $t("Year") }} : </label>
              <textarea v-model="year" type="text" id="year" />
            </div>
            <hr />
            <div class="form-line">
              <label for="amount">{{ $t("Amount") }} : </label>
              <textarea v-model="amount" type="text" id="amount" />
            </div>
            <hr />
            <div class="form-line">
              <label for="grade">{{ $t("Grade") }} : </label>
              <textarea v-model="grade" type="text" id="grade" />
            </div>
            <hr />
            <div class="form-line">
              <label for="signature">{{ $t("Signature") }} : </label>
              <textarea v-model="signature" type="textarea" id="signature" />
            </div>
            <hr />
            <div class="form-line">
              <label for="comment">{{ $t("Comments") }} : </label>
              <textarea
                v-model="comment"
                type="textarea"
                id="comment"
                class="comment-section"
              />
            </div>
            <hr />
            <div class="form-line">
              <label for="status">{{ $t("Status") }} : </label>
              <select v-model="status" id="status">
                <option value="holding">With Company</option>
                <option value="returned">With User</option>
              </select>
            </div>
            <hr />
            <b-button type="submit" class="button mint">
              {{ $t("MINT") }}
            </b-button>
            <div class="error">{{ mintError && mintError }}</div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // import Vue from "vue";
  // import VueSweetalert2 from "vue-sweetalert2";
  // import "sweetalert2/dist/sweetalert2.min.css";

  // Vue.use(VueSweetalert2);
  var axios = require("axios");
  import ABI from "../assets/ABI/AdminContract.json";
  const { ethers } = require("ethers");
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  export default {
    name: "Validation",
    data() {
      return {
        fronturl: "",
        backurl: "",
        pioneerAddress: "",
        pmgCert: "",
        noteDesc: "",
        note: "",
        serial: "",
        country: "",
        year: "",
        amount: "",
        series: "",
        grade: "",
        comment: "",
        signature: "",
        frontImage: "",
        backImage: "",
        type: "",
        typeList: "",
        category: "",
        categoryList: "",
        contractABI: ABI,
        contract: "",
        authorisedAddress: [],
        mintError: "",
        validImageTypes: ["jpg", "jpeg", "png"],
        frontImageError: "",
        backImageError: "",
        pmgData: "",
        contractAddress: process.env.VUE_APP_CONTRACT_ADDRESS,
        ownerAddress: "",
        status: "holding",
        url: process.env.VUE_APP_API_URL,
      };
    },
    mounted: async function () {
      if (window.localStorage.getItem("address")) {
        try {
          const signer = provider.getSigner(); //user
          this.contract = new ethers.Contract(
            this.contractAddress,
            this.contractABI,
            signer
          );
          // this.ownerAddress = await this.contract.owner();
        } catch (e) {
          console.log(e.message);
        }

        try {
          axios({
            method: "get",
            url: this.url + "api/admin/",
            headers: {
              "Content-Type": "application/json",
            },
          }).then((response) => {
            response.data.map((data) => {
              if (data.status === true) {
                this.authorisedAddress.push(data);
              }
            });
            // this.authorisedAddress = response.data;
            // this.authorisedAddress.push({ address: this.ownerAddress });
          });

          axios({
            method: "get",
            url: this.url + "api/category/",
            headers: {
              "Content-Type": "application/json",
            },
          }).then((response) => (this.categoryList = response.data));

          axios({
            method: "get",
            url: this.url + "api/type/",
            headers: {
              "Content-Type": "application/json",
            },
          }).then((response) => (this.typeList = response.data));
        } catch (e) {
          console.log("error", e.message);
        }
      }
    },
    methods: {
      onFrontFileChange: async function (e) {
        if (e.target.files && e.target.files[0]) {
          let imageType = e.target.files[0].name.split(".");
          for (let i = 0; i < this.validImageTypes.length; i++) {
            if (
              imageType[imageType.length - 1].toLowerCase() ==
              this.validImageTypes[i]
            ) {
              this.fronturl = URL.createObjectURL(e.target.files[0]);
              this.frontImage = e.target.files[0];
              this.frontImageError = "";
              break;
            } else {
              this.fronturl = "";
              this.frontImage = "";
              this.frontImageError =
                "Image type must be either jpg, jpeg or png";
            }
          }
        }
      },
      onBackFileChange: function (e) {
        if (e.target.files && e.target.files[0]) {
          let imageType = e.target.files[0].name.split(".");
          for (let i = 0; i < this.validImageTypes.length; i++) {
            if (
              imageType[imageType.length - 1].toLowerCase() ==
              this.validImageTypes[i]
            ) {
              this.backurl = URL.createObjectURL(e.target.files[0]);
              this.backImage = e.target.files[0];
              this.backImageError = "";
              break;
            } else {
              this.backurl = "";
              this.backImage = "";
              this.backImageError =
                "Image type must be either jpg, jpeg or png";
            }
          }
        }
      },
      showAlert(type, title, state, loadingTitle) {
        this.$swal
          .fire({
            position: "center",
            icon: type,
            title: title,
            showConfirmButton: true,
            backdrop: true,
            allowOutsideClick: false,
          })
          .then((result) => {
            if (result.isConfirmed && state == 0) {
              this.showLoading(loadingTitle);
            } else if (result.isConfirmed && state == 1) {
              window.location.reload();
            }
          });
      },
      showLoading(title) {
        this.$swal.fire({
          position: "center",
          title: title,
          showConfirmButton: false,
          backdrop: true,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
        });
      },
      confirmMessage() {
        // this.$bvModal.msgBoxConfirm("Are you sure?");
        const h = this.$createElement;
        const messageVNode = h("div", { class: ["foobar"] }, [
          h("div", { class: ["text-start"] }, [
            h("p", { class: ["text-title"] }, ["Type"]),
            h("div", { class: ["text-data"] }, [
              h("p", { class: ["text-start"] }, [" : ", this.type]),
            ]),
          ]),
          h("div", { class: ["text-start"] }, [
            h("p", { class: ["text-title"] }, ["Category"]),
            h("div", { class: ["text-data"] }, [
              h("p", { class: ["text-start"] }, [" : ", this.category]),
            ]),
          ]),
          h("div", { class: ["text-start"] }, [
            h("p", { class: ["text-title"] }, ["Pioneer Address"]),
            h("div", { class: ["text-data"] }, [
              h("p", { class: ["text-start"] }, [" : ", this.pioneerAddress]),
            ]),
          ]),
          h("div", { class: ["text-start"] }, [
            h("p", { class: ["text-title"] }, ["PMG Cert #"]),
            h("div", { class: ["text-data"] }, [
              h("p", { class: ["text-start"] }, [" : ", this.pmgCert]),
            ]),
          ]),
          h("div", { class: ["text-start"] }, [
            h("p", { class: ["text-title"] }, ["Note Description"]),
            h("div", { class: ["text-data"] }, [
              h("p", { class: ["text-start"] }, [" : ", this.noteDesc]),
            ]),
          ]),
          h("div", { class: ["text-start"] }, [
            h("p", { class: ["text-title"] }, ["Note #"]),
            h("div", { class: ["text-data"] }, [
              h("p", { class: ["text-start"] }, [" : ", this.note]),
            ]),
          ]),
          h("div", { class: ["text-start"] }, [
            h("p", { class: ["text-title"] }, ["Serial #"]),
            h("div", { class: ["text-data"] }, [
              h("p", { class: ["text-start"] }, [" : ", this.serial]),
            ]),
          ]),
          h("div", { class: ["text-start"] }, [
            h("p", { class: ["text-title"] }, ["Country"]),
            h("div", { class: ["text-data"] }, [
              h("p", { class: ["text-start"] }, [" : ", this.country]),
            ]),
          ]),
          h("div", { class: ["text-start"] }, [
            h("p", { class: ["text-title"] }, ["Year"]),
            h("div", { class: ["text-data"] }, [
              h("p", { class: ["text-start"] }, [" : ", this.year]),
            ]),
          ]),
          h("div", { class: ["text-start"] }, [
            h("p", { class: ["text-title"] }, ["Amount"]),
            h("div", { class: ["text-data"] }, [
              h("p", { class: ["text-start"] }, [" : ", this.amount]),
            ]),
          ]),
          h("div", { class: ["text-start"] }, [
            h("p", { class: ["text-title"] }, ["Grade"]),
            h("div", { class: ["text-data"] }, [
              h("p", { class: ["text-start"] }, [" : ", this.grade]),
            ]),
          ]),
          h("div", { class: ["text-start"] }, [
            h("p", { class: ["text-title"] }, ["Signature"]),
            h("div", { class: ["text-data"] }, [
              h("p", { class: ["text-start"] }, [" : ", this.signature]),
            ]),
          ]),
          h("div", { class: ["text-start"] }, [
            h("p", { class: ["text-title"] }, ["Comments"]),
            h("div", { class: ["text-data"] }, [
              h("p", { class: ["text-start"] }, [" : ", this.comment]),
            ]),
          ]),
        ]);
        this.$bvModal
          .msgBoxConfirm(messageVNode, {
            title: "Are you Sure?",
            okTitle: "OK",
            cancelTitle: "Cancel",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value == true) {
              this.mint();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      async mint() {
        if ((this.frontImage != "") & (this.backImage != "")) {
          if ((this.category != "choose") & (this.type != "choose")) {
            this.mintError = "";
            let contractMinted = false;
            let DBstored = false;
            let certUniq = true;
            try {
              await axios({
                method: "get",
                url: this.url + "api/admin/pmg/validation/" + this.pmgCert,
              }).then((response) => {
                certUniq = response.data.status;
              });
            } catch (e) {
              this.mintError = "Mint failed.";
            }

            if (this.contract && certUniq == false) {
              if (ethers.utils.isAddress(this.pioneerAddress)) {
                try {
                  this.showLoading("Minting...");

                  const mintAction = await this.contract.devMint(
                    this.pioneerAddress,
                    this.pmgCert
                  );
                  const receipt = await provider.waitForTransaction(
                    mintAction.hash
                  );
                  // console.log(
                  //   "tokenId: ",
                  //   parseInt(receipt.logs[0].topics[3], 16)
                  // );

                  if (!receipt) {
                    this.showAlert(
                      "error",
                      "Contract Error, minting failed.",
                      2
                    );
                  } else {
                    contractMinted = true;
                    this.showAlert(
                      "success",
                      "Contract Mint Success.",
                      0,
                      "Uploading to DB..."
                    );

                    // contractMinted = true;
                    let data = new FormData();
                    data.append("token_hash", mintAction.hash);
                    data.append("category", this.category);
                    data.append("type", this.type);
                    data.append("front", this.frontImage, this.frontImage.name);
                    data.append("back", this.backImage, this.backImage.name);
                    data.append("pmg_cert", this.pmgCert);
                    data.append(
                      "description",
                      this.noteDesc ? this.noteDesc : "NA"
                    );
                    data.append("note", this.note ? this.note : "NA");
                    data.append("serial", this.serial ? this.serial : "NA");
                    data.append("country", this.country ? this.country : "NA");
                    data.append("year", this.year ? this.year : "NA");
                    data.append("amount", this.amount ? this.amount : "NA");
                    data.append("grade", this.grade ? this.grade : "NA");
                    data.append(
                      "signature",
                      this.signature ? this.signature : "NA"
                    );
                    data.append("comment", this.comment ? this.comment : "NA");
                    data.append("pioneer_address", this.pioneerAddress);
                    data.append("status", this.status);
                    try {
                      await axios({
                        method: "post",
                        url: this.url + "api/pmg/create",
                        data: data,
                        headers: {
                          "Content-Type": "multipart/form-data",
                          Authorization:
                            "Basic YWRtaW46TExEUE54X1pxOTQ/QWEhUw==",
                        },
                      }).then((response) => {
                        if (response.data.status == "Success") {
                          DBstored = true;
                        }
                      });
                    } catch (e) {
                      console.log(e.message);
                      this.showAlert("error", "DB Error, upload failed.", 2);
                    }

                    if (contractMinted == true && DBstored == true) {
                      this.showAlert("success", "DB Store Success", 1);
                    }
                  }
                } catch (e) {
                  if (
                    e.message ==
                    "MetaMask Tx Signature: User denied transaction signature."
                  ) {
                    this.showAlert("error", "Transaction Rejected.", 2);
                  } else {
                    this.showAlert(
                      "error",
                      "Not authorised for this action",
                      2
                    );
                  }
                  console.log(e.message);
                }
              } else {
                this.mintError = "Invalid Pioneer Address format.";
              }
            } else {
              this.mintError = "PMG Cert is exist.";
            }
          } else {
            this.mintError = "Category or Type is not selected.";
          }
        } else {
          this.mintError = "Make sure all image is uploaded.";
        }
      },
      connectWallet() {
        this.$emit("connect");
      },
      checkPrePMG() {
        axios({
          method: "get",
          url: this.url + `api/runner/one_prepmg?pmg_cert=${this.pmgCert}`,
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            this.showAlert("success", "PMG Cert found.", 2);
            this.amount = response.data.amount;
            this.comment = response.data.comment;
            this.country = response.data.country;
            this.noteDesc = response.data.description;
            this.grade = response.data.grade;
            this.note = response.data.note;
            this.pioneerAddress = response.data.pioneer_address;
            this.year = response.data.year;
            this.serial = response.data.serial;
            this.signature = response.data.signature;
            if (response.data.status == "pending") {
              this.status = "holding";
            } else {
              this.status = "returned";
            }
          })
          .catch(() => {
            this.showAlert("error", "PMG Cert not existed.", 2);
            this.amount = "";
            this.comment = "";
            this.country = "";
            this.noteDesc = "";
            this.grade = "";
            this.note = "";
            this.pioneerAddress = "";
            this.year = "";
            this.serial = "";
            this.signature = "";
            this.status = "holding";
          });
      },
    },
  };
</script>

<style>
  .body {
    height: 100%;
  }
  .body-container {
    box-shadow: 2px 2px 10px 10px #5454d0;
    width: 80%;
    margin: 0 auto;
    padding: 50px;
    height: 100%;
    margin-top: 30px;
    margin-bottom: 50px;
    color: white;
    background-color: rgba(211, 211, 211, 0.1);
  }

  hr {
    height: 2px solid !important;
  }

  .form-data {
    display: flex;
    flex-direction: row;
  }

  .form-details {
    width: 100%;
  }

  .title,
  .image-section {
    display: flex;
    justify-content: flex-start;
  }

  .image-section {
    flex-direction: column;
    justify-content: space-evenly;
  }

  .image-section > div {
    margin-right: 20px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .image-section > div > img {
    align-self: center;
    margin-bottom: 20px;
  }

  .image-display {
    width: 450px;
    height: 450px;
    border: 2px solid gold;
  }

  .form-line {
    font-size: 1.2em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .form-line > textarea,
  .form-line > select {
    display: flex;
    justify-content: flex-end;
    border-radius: 10px;
    border: none;
    padding: 3px 15px;
    width: 70%;
    height: 40px;
    font-size: 0.8em;
  }

  .form-line > div {
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
  }

  .form-line > div > textarea {
    display: flex;
    justify-content: flex-end;
    border-radius: 10px;
    border: none;
    padding: 3px 15px;
    width: 100%;
    height: 40px;
    font-size: 0.8em;
  }

  .comment-section {
    height: 100px !important;
  }

  .button {
    border: 1px solid #5454d0;
    margin-top: 10px;
    background: linear-gradient(to bottom, #5454d0 100%, #2a25b5 100%);
    color: white;
    text-shadow: 0px 1px #000000;
    border-radius: 33px;
    transition: 1s;
    background-size: 200% 200%;
    padding: 10px;
  }

  .mint {
    margin: 0 auto;
    width: 40%;
  }

  .button:hover {
    opacity: 0.8;
  }

  .error {
    color: red;
    margin-top: 5px;
  }

  .modal-dialog {
    max-width: 70% !important;
    height: 100%;
    line-break: anywhere;
  }

  .text-start {
    font-size: 1.1em;
    margin-bottom: 0px;
    display: flex;
    flex-direction: row;
  }

  .modal.fade {
    /* display: flex !important; */
    overflow: hidden !important;
  }

  .text-title {
    width: 150px;
  }

  @media screen and (min-width: 1600px) {
    .image-section {
      margin-right: 20px;
    }
  }

  @media screen and (max-width: 1600px) {
    .form-data {
      flex-direction: column;
    }

    .uploadImage {
      margin-bottom: 20px;
    }

    .form-line > textarea {
      font-size: 1em;
    }

    .form-line > div > textarea {
      font-size: 1em;
    }

    .image-section {
      flex-direction: row;
    }
  }

  @media screen and (max-width: 1500px) {
    .image-section {
      flex-direction: column;
    }

    .image-section > div > button {
      /* align-self: center; */
      margin-top: 15px;
    }
  }

  .checkPMG {
    position: absolute;
    margin-top: 5px;
    font-size: 0.8em;
    padding: 3px;
    right: 10px;
  }
</style>
