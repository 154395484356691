<template>
  <div>
    <!-- title-section -->
    <b-tabs fill>
      <b-tab title="Type">
        <div class="body-container">
          <div class="title">
            <h1>{{ $t("Type") }}</h1>
            <div>
              <b-button
                class="b-button button"
                v-b-modal.modal-prevent-closing
                >{{ $t("Add") }}</b-button
              >
            </div>
          </div>
          <br />
          <div class="data-table">
            <div class="data-header">
              <div>{{ $t("ID") }}</div>
              <div>{{ $t("Name") }}</div>
              <div>{{ $t("Action") }}</div>
            </div>
            <hr />
            <div class="data-body" v-for="type in typeList" :key="type.id">
              <div>{{ type.id }}</div>
              <div>{{ type.name }}</div>
              <div>
                <!-- <button @click="editType(type.id)">{{ $t("Edit") }}</button> -->
                <button
                  class="b-button button"
                  @click="storeType(type.id)"
                  v-b-modal.modal-prevent-closing1
                >
                  {{ $t("Edit") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab title="Category">
        <div>
          <b-modal
            id="modal-prevent-closing"
            ref="modal"
            @show="resetModal"
            @hidden="resetModal"
            @ok.prevent="addTypeList"
            title="Add Type"
          >
            <form ref="form" @submit.stop.prevent="addTypeList">
              <b-form-group
                label="Type Name"
                label-for="typeName"
                :state="nameState"
                invalid-feedback="Type Name is required."
              >
                <b-form-input
                  id="typeName"
                  v-model="addType"
                  :state="nameState"
                  required
                ></b-form-input>
              </b-form-group>
              <div class="error">
                {{ this.addTypeError && this.addTypeError }}
              </div>
            </form>
          </b-modal>
          <b-modal
            id="modal-prevent-closing1"
            ref="modal"
            @show="resetModal"
            @hidden="resetModal"
            @ok.prevent="editTypeList()"
            title="Edit Type"
          >
            <form ref="form" @submit.stop.prevent="editTypeList">
              <p>ID : {{ this.selectedTypeID }}</p>
              <b-form-group
                label="Type Name"
                label-for="typeName"
                :state="nameState"
                invalid-feedback="Type Name is required."
              >
                <b-form-input
                  id="typeName"
                  v-model="editType"
                  :state="nameState"
                  required
                ></b-form-input>
              </b-form-group>
              <div class="error">
                {{ this.editTypeError && this.editTypeError }}
              </div>
            </form>
          </b-modal>
        </div>
        <!-- category-section -->
        <div class="body-container">
          <div class="title">
            <h1>{{ $t("Category") }}</h1>
            <div>
              <b-button
                class="b-button button"
                v-b-modal.modal-prevent-closing2
                >{{ $t("Add") }}</b-button
              >
            </div>
          </div>
          <br />
          <div class="data-table">
            <div class="data-header">
              <div>{{ $t("ID") }}</div>
              <div>{{ $t("Name") }}</div>
              <div>{{ $t("Action") }}</div>
            </div>
            <hr />
            <div
              class="data-body"
              v-for="category in categoryList"
              :key="category.id"
            >
              <div>{{ category.id }}</div>
              <div>{{ category.name }}</div>
              <div>
                <button
                  class="b-button button"
                  @click="storeCat(category.id)"
                  v-b-modal.modal-prevent-closing3
                >
                  {{ $t("Edit") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </b-tab></b-tabs
    >
    <div>
      <b-modal
        id="modal-prevent-closing2"
        ref="modal"
        @show="resetModal"
        @hidden="resetModal"
        @ok.prevent="addCategoryList"
        title="Add Category"
      >
        <form ref="form" @submit.stop.prevent="addCategoryList">
          <b-form-group
            label="Category Name"
            label-for="categoryName"
            :state="nameState"
            invalid-feedback="Category Name is required."
          >
            <b-form-input
              id="categoryName"
              v-model="addCategory"
              :state="nameState"
              required
            ></b-form-input>
          </b-form-group>
          <div class="error">
            {{ this.addCatError && this.addCatError }}
          </div>
        </form>
      </b-modal>
      <b-modal
        id="modal-prevent-closing3"
        ref="modal"
        @show="resetModal"
        @hidden="resetModal"
        @ok.prevent="editCategoryList()"
        title="Edit Category"
      >
        <form ref="form" @submit.stop.prevent="editCategoryList">
          <p>ID : {{ this.selectedCatID }}</p>
          <b-form-group
            label="Category Name"
            label-for="categoryName"
            :state="nameState"
            invalid-feedback="Category Name is required."
          >
            <b-form-input
              id="categoryName"
              v-model="editCategory"
              :state="nameState"
              required
            ></b-form-input>
          </b-form-group>
          <div class="error">
            {{ this.editCatError && this.editCatError }}
          </div>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
  // import Vue from "vue";
  // import VueSweetalert2 from "vue-sweetalert2";
  // import "sweetalert2/dist/sweetalert2.min.css";

  // Vue.use(VueSweetalert2);
  var axios = require("axios");
  import ABI from "../assets/ABI/AdminContract.json";
  const { ethers } = require("ethers");
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  export default {
    name: "Edit",
    data() {
      return {
        signer: "",
        typeList: "",
        categoryList: "",
        addCategory: "",
        selectedCatID: "",
        editCategory: "",
        addType: "",
        selectedTypeID: "",
        editType: "",
        addCatError: "",
        editCatError: "",
        addTypeError: "",
        editTypeError: "",
        contractABI: ABI,
        contract: "",
        authorisedAddress: [],
        pmgData: "",
        nameState: null,
        contractAddress: process.env.VUE_APP_CONTRACT_ADDRESS,
        ownerAddress: "",
        url: process.env.VUE_APP_API_URL,
      };
    },
    mounted: async function () {
      if (window.localStorage.getItem("address")) {
        try {
          const signer = provider.getSigner(); //user
          this.signer = await signer.getAddress();
          this.contract = new ethers.Contract(
            this.contractAddress,
            this.contractABI,
            signer
          );
          // this.ownerAddress = await this.contract.owner();
        } catch (e) {
          console.log(e.message);
        }

        try {
          axios({
            method: "get",
            url: this.url + "api/admin/",
            headers: {
              "Content-Type": "application/json",
            },
          }).then((response) => {
            response.data.map((data) => {
              if (data.status === true) {
                this.authorisedAddress.push(data);
              }
            });
            // this.authorisedAddress = response.data;
            // this.authorisedAddress.push({ address: this.ownerAddress });
          });

          axios({
            method: "get",
            url: this.url + "api/category/",
            headers: {
              "Content-Type": "application/json",
            },
          }).then((response) => (this.categoryList = response.data));

          axios({
            method: "get",
            url: this.url + "api/type/",
            headers: {
              "Content-Type": "application/json",
            },
          }).then((response) => (this.typeList = response.data));
        } catch (e) {
          console.log("error", e.message);
        }
      }
    },
    methods: {
      checkFormValidity() {
        const valid = this.$refs.form.checkValidity();
        this.nameState = valid;
        return valid;
      },
      resetModal() {
        this.addType = "";
        this.addTypeError = "";
        this.editType = "";
        this.editTypeError = "";
        this.addCategory = "";
        this.addCatError = "";
        this.editCategory = "";
        this.editCatError = "";
        this.nameState = null;
      },
      storeType(id) {
        this.selectedTypeID = id;
      },
      storeCat(id) {
        this.selectedCatID = id;
      },
      showAlert(type, title, state) {
        this.$swal
          .fire({
            position: "center",
            icon: type,
            title: title,
            showConfirmButton: true,
            backdrop: true,
            allowOutsideClick: false,
          })
          .then((result) => {
            if (result.isConfirmed && state == 0) {
              window.location.reload();
            }
          });
      },
      showLoading() {
        this.$swal.fire({
          position: "center",
          title: "Processing...",
          showConfirmButton: false,
          backdrop: true,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
        });
      },
      addCategoryList() {
        if (!this.checkFormValidity()) {
          return;
        }
        if (this.addCategory.charAt(0) == " ") {
          this.addCatError = "Make sure no space in the first character.";
        } else {
          let success = false;
          if (this.addCategory && /.*\S.*/i.test(this.addCategory)) {
            for (let i = 0; i < this.authorisedAddress.length; i++) {
              if (this.signer == this.authorisedAddress[i].address) {
                success = true;
                this.showLoading();
                try {
                  axios({
                    method: "post",
                    url: this.url + "api/category/create",
                    data: { name: this.addCategory },
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "Basic YWRtaW46TExEUE54X1pxOTQ/QWEhUw==",
                    },
                  }).then(() => {
                    this.showAlert("success", "Add Success.", 0);
                  });
                } catch (e) {
                  this.showAlert("error", "Add Failed.", 1);
                  console.log("error", e.message);
                }
              }
            }
            if (success == false) {
              this.addCatError = "Not authorised for this action.";
            }
          } else {
            this.addCatError = "Cannot be Empty.";
          }
        }
      },
      editCategoryList() {
        if (!this.checkFormValidity()) {
          return;
        }
        if (this.editCategory.charAt(0) == " ") {
          this.editCatError = "Make sure no space in the first character.";
        } else {
          let success = false;
          if (this.editCategory && /.*\S.*/i.test(this.editCategory)) {
            for (let i = 0; i < this.authorisedAddress.length; i++) {
              if (this.signer == this.authorisedAddress[i].address) {
                success = true;
                this.showLoading();
                try {
                  axios({
                    method: "post",
                    url: this.url + "api/category/update/" + this.selectedCatID,
                    data: { name: this.editCategory },
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "Basic YWRtaW46TExEUE54X1pxOTQ/QWEhUw==",
                    },
                  }).then(() => {
                    this.showAlert("success", "Edit Success.", 0);
                  });
                } catch (e) {
                  this.showAlert("error", "Edit Failed.", 1);
                }
              }
            }
            if (success == false) {
              this.editCatError = "Not authorised for this action.";
            }
          } else {
            this.editCatError = "Cannot be Empty.";
          }
        }
      },
      addTypeList() {
        if (!this.checkFormValidity()) {
          return;
        }
        if (this.addType.charAt(0) == " ") {
          this.addTypeError = "Make sure no space in the first character.";
        } else {
          let success = false;
          if (this.addType && /.*\S.*/i.test(this.addType)) {
            for (let i = 0; i < this.authorisedAddress.length; i++) {
              if (this.signer == this.authorisedAddress[i].address) {
                success = true;
                this.showLoading();
                try {
                  axios({
                    method: "post",
                    url: this.url + "api/type/create",
                    data: { name: this.addType },
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "Basic YWRtaW46TExEUE54X1pxOTQ/QWEhUw==",
                    },
                  }).then(() => {
                    this.showAlert("success", "Add Success.", 0);
                  });
                } catch (e) {
                  this.showAlert("error", "Add Failed.", 1);
                  console.log("error", e.message);
                }
              }
            }
            if (success == false) {
              this.addTypeError = "Not authorised for this action.";
            }
          } else {
            this.addTypeError = "Cannot be Empty.";
          }
        }
      },
      editTypeList() {
        if (!this.checkFormValidity()) {
          return;
        }
        if (this.editType.charAt(0) == " ") {
          this.editTypeError = "Make sure no space in the first character.";
        } else {
          let success = false;
          if (this.editType && /.*\S.*/i.test(this.editType)) {
            for (let i = 0; i < this.authorisedAddress.length; i++) {
              if (this.signer == this.authorisedAddress[i].address) {
                success = true;
                this.showLoading();
                try {
                  axios({
                    method: "post",
                    url: this.url + "api/type/update/" + this.selectedTypeID,
                    data: { name: this.editType },
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "Basic YWRtaW46TExEUE54X1pxOTQ/QWEhUw==",
                    },
                  }).then(() => {
                    this.showAlert("success", "Edit Success.", 0);
                  });
                } catch (e) {
                  this.showAlert("error", "Edit Failed.", 1);
                  console.log("error", e.message);
                }
              }
            }
            if (success == false) {
              this.editTypeError = "Not authorised for this action.";
            }
          } else {
            this.editTypeError = "Cannot be Empty.";
          }
        }
      },
    },
  };
</script>

<style>
  .form-line > input {
    display: flex;
    justify-content: flex-end;
    border-radius: 10px;
    border: none;
    padding: 3px 15px;
    width: 70%;
    height: 40px;
    font-size: 0.8em;
  }

  .data-header,
  .data-body {
    display: flex;
    flex-direction: row;
    padding: 10px 0px;
  }

  .data-header > div,
  .data-body > div {
    width: 30%;
    text-align: start;
  }

  .b-button,
  .btn {
    display: flex !important;
    border: 1px solid #5454d0;
    background: linear-gradient(to bottom, #5454d0 100%, #2a25b5 100%);
    color: white;
    text-shadow: 0px 1px #000000;
    border-radius: 33px !important;
    transition: 1s;
    justify-content: center;
  }

  .close {
    background: none;
    border: none;
    font-size: 1.5em;
  }

  .button:hover {
    opacity: 0.8;
  }

  .title {
    display: flex;
    justify-content: space-between;
  }
</style>
