<template>
  <div>
    <div class="status-header">
      <h1>A.D</h1>
    </div>
    <div>
      <b-table
        :items="runnerData"
        :per-page="perPage"
        :current-page="currentPage"
        :fields="fields"
        show-empty
        small
        responsive
        empty-filtered-text="No Data"
        class="PMG-table"
      >
        <template #cell(id)="row">
          {{ row.item.id }}
        </template>
        <template #cell(email)="row">
          {{ row.item.email }}
        </template>
        <template #cell(name)="row">
          {{ row.item.name }}
        </template>
        <template #cell(register_at)="row">
          {{ getDate(row.item.register_at) }}
        </template>
        <template #cell(total_collected)="row">
          {{ row.item.total_collected }}
        </template>
        <template #cell(action)="row">
          <button
            class="b-button runnerbutton button"
            @click="confirmMessage(row.item.id, row.item.email, row.item.name)"
          >
            Delete
          </button>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
  import ABI from "../assets/ABI/AdminContract.json";
  var axios = require("axios");
  var moment = require("moment");
  const { ethers } = require("ethers");
  const provider = new ethers.providers.Web3Provider(window.ethereum);

  export default {
    name: "Runner",
    data() {
      return {
        fields: [
          { key: "id", label: "ID" },
          { key: "email", label: "Email" },
          { key: "name", label: "Name" },
          {
            key: "register_at",
            label: "Register Date",
            formatter: this.getDate,
          },
          { key: "total_collected", label: "Total Collected" },
          { key: "action", label: "Action" },
        ],
        currentPage: 1,
        perPage: 100,
        url: process.env.VUE_APP_API_URL,
        runnerData: "",
        contract: "",
        contractABI: ABI,
        contractAddress: process.env.VUE_APP_CONTRACT_ADDRESS,
        signer: "",
        authorisedAddress: [],
      };
    },
    mounted: async function () {
      if (window.localStorage.getItem("address")) {
        try {
          const signer = provider.getSigner(); //user
          this.signer = await signer.getAddress();
          this.contract = new ethers.Contract(
            this.contractAddress,
            this.contractABI,
            signer
          );
          // this.ownerAddress = await this.contract.owner();
        } catch (e) {
          console.log(e.message);
        }
      }
      this.getAdmin();
      this.getRunner();
    },
    methods: {
      reloadPage() {
        window.location.reload();
      },
      getAdmin() {
        if (this.contract) {
          try {
            axios({
              method: "get",
              url: this.url + "api/admin/",
              // headers: { ...data.getHeaders() },
            }).then((response) => {
              response.data.map((data) => {
                if (data.status === true) {
                  this.authorisedAddress.push(data);
                }
              });
              // this.authorisedAddress = response.data;
              // this.authorisedAddress.push({ address: this.ownerAddress });
            });
          } catch (e) {
            console.log("error", e.message);
          }
        }
      },
      getRunner() {
        try {
          axios({
            method: "get",
            url: this.url + "api/runner/all_runner",
            // headers: { ...data.getHeaders() },
          }).then((response) => {
            this.runnerData = response.data.results;
            this.perPage = response.data.metadata.total;
            console.log(this.perPage);
          });
        } catch (e) {
          console.log("error", e.message);
        }
      },
      getDate(date) {
        return moment(date).format("DD/MM/YYYY hh:mm:ss a");
      },
      deleteRunner(id) {
        let success = false;
        for (let i = 0; i < this.authorisedAddress.length; i++) {
          if (this.authorisedAddress[i].address == this.signer) {
            success = true;
            try {
              axios({
                method: "post",
                url: this.url + "api/runner/delete",
                headers: {
                  Authorization: "Basic YWRtaW46TExEUE54X1pxOTQ/QWEhUw==",
                },
                data: {
                  id: id,
                },
              }).then(() => {
                this.showAlert("success", "Success Delete", 0);
              });
            } catch (e) {
              this.showAlert("error", "Delete Failed.", 1);
              console.log("error", e.message);
            }
          }
        }
        if (success == false) {
          this.showAlert("error", "Not authorised for this action.", 1);
        }
      },
      showAlert(type, title, state) {
        this.$swal
          .fire({
            position: "center",
            icon: type,
            title: title,
            showConfirmButton: true,
            backdrop: true,
            allowOutsideClick: false,
          })
          .then((result) => {
            if (result.isConfirmed && state == 0) {
              this.reloadPage();
            }
          });
      },
      confirmMessage(id, email, name) {
        const h = this.$createElement;
        this.messageVNodeList = h("div", { class: ["foobar"] }, [
          h("div", { class: ["text-start"] }, [
            h("p", { class: ["text-title"] }, ["ID"]),
            h("div", { class: ["text-data"] }, [
              h("p", { class: ["text-start"] }, [" : ", id]),
            ]),
          ]),
          h("div", { class: ["text-start"] }, [
            h("p", { class: ["text-title"] }, ["Email"]),
            h("div", { class: ["text-data"] }, [
              h("p", { class: ["text-start"] }, [" : ", email]),
            ]),
          ]),
          h("div", { class: ["text-start"] }, [
            h("p", { class: ["text-title"] }, ["Name"]),
            h("div", { class: ["text-data"] }, [
              h("p", { class: ["text-start"] }, [" : ", name]),
            ]),
          ]),
        ]);

        this.$bvModal
          .msgBoxConfirm(this.messageVNodeList, {
            title: "Are you Sure?",
            okTitle: "OK",
            cancelTitle: "Cancel",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value == true) {
              this.deleteRunner(id);
              //   window.location.reload();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
    },
  };
</script>

<style>
  .runnerbutton {
    margin-top: 0px;
    margin: 0 auto;
  }

  .modal-body {
    height: 100%;
  }
</style>
