<template>
  <div>
    <b-tabs content-class="mt-3" fill>
      <b-tab title="Update PMG" @click="getPMG(1)" active>
        <div class="updatepmg">
          <div class="status-header">
            <h1>Update PMG</h1>
            <div class="function-bar">
              <div class="display">
                <span>{{ $t("Show Per Page") }} : </span>
                <button @click="perPage = 5">5</button>
                <button @click="perPage = 20">20</button>
                <button @click="perPage = 50">50</button>
                <button @click="perPage = 100">100</button>
              </div>
              <div>
                Check All
                <input
                  type="checkbox"
                  v-model="checkAll"
                  @click="checkAllBox"
                />
              </div>
            </div>
          </div>
          <b-table
            :items="pmgData"
            :per-page="0"
            :current-page="currentPage"
            :fields="fields"
            show-empty
            small
            responsive
            empty-filtered-text="No Data"
            class="PMG-table"
            id="pmg-table"
          >
            <template #empty="scope">
              <p class="text-center">{{ (scope.emptyText = "No Data") }}</p>
            </template>
            <template #cell(Index)="row">
              {{ row.index + 1 }}
            </template>
            <template #cell(token_id)="row">
              <input
                type="checkbox"
                v-model="checkData"
                class="checkInput"
                :value="row.item.token_id"
              />
            </template>
            <template #cell(pmg_cert)="row">
              {{ row.item.pmg_cert }}
            </template>
            <template #cell(contract_address)="row">
              {{ row.item.contract_address }}
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="pmg-table"
            align="center"
          ></b-pagination>
          <button class="button" @click="confirmMessage">Submit</button>
          <div class="error">{{ this.updateError }}</div>
        </div>
      </b-tab>
      <b-tab title="Update NFT" @click="getNFT(1)">
        <div class="updatepmg">
          <div class="status-header">
            <h1>Update NFT</h1>
            <div class="function-bar">
              <div class="display">
                <span>{{ $t("Show Per Page") }} : </span>
                <button @click="perPage = 5">5</button>
                <button @click="perPage = 20">20</button>
                <button @click="perPage = 50">50</button>
                <button @click="perPage = 100">100</button>
              </div>
              <div>
                Check All
                <input
                  type="checkbox"
                  v-model="checkAll"
                  @click="checkAllBox"
                />
              </div>
            </div>
          </div>
          <b-table
            :items="pmgData"
            :per-page="0"
            :current-page="currentPage"
            :fields="fields"
            show-empty
            small
            responsive
            empty-filtered-text="No Data"
            class="PMG-table"
            id="nft-table"
          >
            <template #empty="scope">
              <p class="text-center">{{ (scope.emptyText = "No Data") }}</p>
            </template>
            <template #cell(Index)="row">
              {{ row.index + 1 }}
            </template>
            <template #cell(token_id)="row">
              <input
                type="checkbox"
                v-model="checkData"
                class="checkInput"
                :value="row.item.token_id"
              />
            </template>
            <template #cell(pmg_cert)="row">
              {{ row.item.pmg_cert }}
            </template>
            <template #cell(contract_address)="row">
              {{ row.item.contract_address }}
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="nft-table"
            align="center"
          ></b-pagination>
          <button class="button" @click="confirmMessage">Submit</button>
          <div class="error">{{ this.updateError }}</div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
  var axios = require("axios");
  import ABI from "../assets/ABI/AdminContract.json";
  import marketABI from "../assets/ABI/MarketplaceContract.json";
  const { ethers } = require("ethers");
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  export default {
    name: "UpdatePMG",
    data() {
      return {
        contractABI: ABI,
        marketABI: marketABI,
        ownerAddress: "",
        signer: "",
        signerAddress: "",
        perPage: "20",
        pmgData: "",
        checkData: [],
        finalData: [],
        fields: [
          "Index",
          { key: "pmg_cert", label: "PMG Cert" },
          { key: "contract_address", label: "NFT Contract Address" },
          { key: "token_id", label: "Check" },
        ],
        currentPage: 1,
        totalRows: "",
        contractAddress: process.env.VUE_APP_CONTRACT_ADDRESS,
        marketAddress: process.env.VUE_APP_MARKET_CONTRACT_ADDRESS,
        url: process.env.VUE_APP_API_URL,
        contract: "",
        updateError: "",
        messageVNodeList: [],
        address: [],
        status: [],
        checkAll: false,

        // pmgData1: [
        //   {
        //     token_id: 1,
        //     pmg_cert: "12332-110",
        //     contract_address: "0923838292",
        //   },
        //   {
        //     token_id: 2,
        //     pmg_cert: "12332-110",
        //     contract_address: "0923838292",
        //   },
        //   {
        //     token_id: 3,
        //     pmg_cert: "12332-110",
        //     contract_address: "0923838292",
        //   },
        //   {
        //     token_id: 4,
        //     pmg_cert: "12332-110",
        //     contract_address: "0923838292",
        //   },
        //   {
        //     token_id: 5,
        //     pmg_cert: "12332-110",
        //     contract_address: "0923838292",
        //   },
        // ],
      };
    },
    mounted: async function () {
      if (window.localStorage.getItem("address")) {
        try {
          this.signer = provider.getSigner(); //user
          this.signerAddress = await this.signer.getAddress();
          this.contract = new ethers.Contract(
            this.contractAddress,
            this.contractABI,
            this.signer
          );
          this.ownerAddress = await this.contract.owner();
        } catch (e) {
          console.log(e.message);
        }
      }
      this.getPMG(this.currentPage);
    },
    watch: {
      perPage: {
        handler() {
          this.getPMG(1);
        },
        deep: true,
      },
      currentPage: {
        handler() {
          this.getPMG(this.currentPage);
        },
        deep: true,
      },
    },
    methods: {
      getPMG(currentPage) {
        this.checkData = [];
        try {
          axios({
            method: "get",
            url:
              this.url +
              `api/admin/nfthbna?pageSize=${this.perPage}&page=${currentPage}`,
            // headers: { ...data.getHeaders() },
          }).then((response) => {
            this.pmgData = response.data.results;
            this.totalRows = response.data.metadata.total;
          });
        } catch (e) {
          console.log(e.message);
        }
      },
      getNFT(currentPage) {
        this.checkData = [];
        try {
          axios({
            method: "get",
            url:
              this.url +
              `api/admin/nfthbna/mbone?pageSize=${this.perPage}&page=${currentPage}`,
            // headers: { ...data.getHeaders() },
          }).then((response) => {
            this.pmgData = response.data.results;
            this.totalRows = response.data.metadata.total;
          });
        } catch (e) {
          console.log(e.message);
        }
      },
      showLoading(title) {
        this.$swal.fire({
          position: "center",
          title: title,
          showConfirmButton: false,
          backdrop: true,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
        });
      },
      showAlert(type, title, state, loadingTitle) {
        this.$swal
          .fire({
            position: "center",
            icon: type,
            title: title,
            showConfirmButton: true,
            backdrop: true,
            allowOutsideClick: false,
          })
          .then((result) => {
            if (result.isConfirmed && state == 0) {
              this.showLoading(loadingTitle);
            } else if (result.isConfirmed && state == 1) {
              window.location.reload();
            }
          });
      },
      async Update() {
        if (this.checkData.length > 0) {
          this.contract = new ethers.Contract(
            this.marketAddress,
            this.marketABI,
            this.signer
          );
          if (this.contract) {
            this.showLoading("Updating...");
            try {
              const updateAction = await this.contract.adminUpdatePMG(
                this.address,
                this.checkData,
                this.status
              );
              const receipt = await provider.waitForTransaction(
                updateAction.hash
              );

              if (!receipt) {
                this.showAlert("error", "Contract Error, update failed.", 2);
              } else {
                this.showAlert("success", "Contract Update Success.", 1);
              }
            } catch (e) {
              if (
                e.message ==
                "MetaMask Tx Signature: User denied transaction signature."
              ) {
                setTimeout(
                  () => this.showAlert("error", "Transaction Rejected.", 2),
                  5000
                );
              } else {
                setTimeout(
                  () =>
                    this.showAlert(
                      "error",
                      "Not authorised for this action",
                      1
                    ),
                  5000
                );
              }
              console.log(e.message);
            }
          }
        }
      },
      confirmMessage() {
        this.gatherData();
        this.messageVNodeList = [];
        if (this.finalData.length <= 0) {
          this.updateError = "Must atleast select one data ";
        } else {
          // this.$bvModal.msgBoxConfirm("Are you sure?");
          const h = this.$createElement;
          for (let i = 0; i < this.finalData.length; i++) {
            const messageVNode = h("div", { class: ["foobar"] }, [
              h("div", { class: ["text-start"] }, [
                h("p", { class: ["text-title"] }, ["Token ID"]),
                h("div", { class: ["text-data"] }, [
                  h("p", { class: ["text-start"] }, [" : ", this.checkData[i]]),
                ]),
              ]),
              h("div", { class: ["text-start"] }, [
                h("p", { class: ["text-title"] }, ["PMG Cert #"]),
                h("div", { class: ["text-data"] }, [
                  h("p", { class: ["text-start"] }, [
                    " : ",
                    this.finalData[i].pmg_cert,
                  ]),
                ]),
              ]),
              h("div", { class: ["text-start"] }, [
                h("p", { class: ["text-title"] }, ["NFT Contract Address"]),
                h("div", { class: ["text-data"] }, [
                  h("p", { class: ["text-start"] }, [" : ", this.address[i]]),
                ]),
              ]),
              h("hr", { class: ["line"] }),
            ]);
            this.messageVNodeList.push(messageVNode);
          }

          this.$bvModal
            .msgBoxConfirm(this.messageVNodeList, {
              title: "Are you Sure?",
              okTitle: "OK",
              cancelTitle: "Cancel",
              footerClass: "p-2",
              hideHeaderClose: false,
              centered: true,
            })
            .then((value) => {
              if (value == true) {
                this.Update();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      },
      gatherData() {
        this.finalData = [];
        if (this.checkAll == true) {
          this.checkData = [];
          for (let i = 0; i < this.pmgData.length; i++) {
            this.finalData.push(this.pmgData[i]);
            this.checkData.push(this.pmgData[i].token_id);
            this.address.push(this.pmgData[i].contract_address);
            this.status.push(1);
          }
        } else {
          for (let i = 0; i < this.pmgData.length; i++) {
            for (let j = 0; j < this.checkData.length; j++) {
              if (this.pmgData[i].token_id == this.checkData[j]) {
                this.finalData.push(this.pmgData[i]);
                this.address.push(this.pmgData[i].contract_address);
                this.status.push(1);
              }
            }
          }
        }
        console.log(this.finalData);
      },
      checkAllBox() {
        this.checkAll = !this.checkAll;
        let elements = document.getElementsByClassName("checkInput");

        // console.log("js log", this.checkAll, elements);
        if (this.checkAll == true) {
          for (let i = 0; i < elements.length; i++) {
            elements[i].checked = true;
          }
        } else {
          for (let i = 0; i < elements.length; i++) {
            elements[i].checked = false;
          }
          // document.getElementsByClassName("checkInput").click();
        }
      },
    },
  };
</script>

<style>
  .updatepmg {
    margin-bottom: 50px;
  }

  .modal-body {
    overflow: auto;
    height: 500px;
  }
</style>
