<template>
  <div>
    <div class="status-header">
      <h1>Subscriber</h1>
      <div class="function-bar">
        <div class="display">
          <span>{{ $t("Show Per Page") }} : </span>
          <button @click="perPage = 5">5</button>
          <button @click="perPage = 20">20</button>
          <button @click="perPage = 50">50</button>
          <button @click="perPage = 100">100</button>
        </div>
      </div>
    </div>
    <b-table
      :items="subData"
      :per-page="0"
      :current-page="currentPage"
      :fields="fields"
      show-empty
      small
      responsive
      empty-filtered-text="No Data"
      class="PMG-table"
      id="sub-table"
    >
      <template #empty="scope">
        <p class="text-center">{{ (scope.emptyText = "No Data") }}</p>
      </template>
      <template #cell(email)="row">
        <div>
          <div>{{ row.item.email }}</div>
        </div>
      </template>
      <template #cell(created_at)="row">
        <div>
          <div>{{ getDate(row.item.created_at) }}</div>
        </div>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      aria-controls="sub-table"
      align="center"
    ></b-pagination>
  </div>
</template>

<script>
  import Vue from "vue";
  import VueSweetalert2 from "vue-sweetalert2";
  import "sweetalert2/dist/sweetalert2.min.css";

  Vue.use(VueSweetalert2);
  var axios = require("axios");
  var moment = require("moment");
  import ABI from "../assets/ABI/AdminContract.json";
  const { ethers } = require("ethers");
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  // import { BIconXSquare, BIconCheckSquare } from "bootstrap-vue";
  import "bootstrap-vue/dist/bootstrap-vue-icons.min.css";

  export default {
    name: "Subscriber",
    data() {
      return {
        signer: "",
        perPage: "20",
        subData: "",
        fields: [{ key: "email", label: "Email" }],
        contractABI: ABI,
        currentPage: 1,
        totalRows: "",
        contractAddress: process.env.VUE_APP_CONTRACT_ADDRESS,
        url: process.env.VUE_APP_API_URL,
        contract: "",
      };
    },
    mounted: async function () {
      if (window.localStorage.getItem("address")) {
        try {
          const signer = provider.getSigner(); //user
          this.signer = await signer.getAddress();
          this.contract = new ethers.Contract(
            this.contractAddress,
            this.contractABI,
            signer
          );
        } catch (e) {
          console.log(e.message);
        }
      }
      this.getSub(this.currentPage);
      this.getAdmin();
    },
    watch: {
      perPage: {
        handler() {
          this.getSub(1);
        },
        deep: true,
      },
      currentPage: {
        handler() {
          this.getSub(this.currentPage);
        },
        deep: true,
      },
    },
    methods: {
      getDate(date) {
        return moment(date).format("DD/MM/YYYY hh:mm:ss a");
      },
      getSub(currentPage) {
        try {
          axios({
            method: "get",
            url:
              this.url +
              `api/admin/subscriber?pageSize=${this.perPage}&page=${currentPage}`,
            // headers: { ...data.getHeaders() },
          }).then((response) => {
            this.subData = response.data.results;
            this.totalRows = response.data.metadata.data;
          });
        } catch (e) {
          console.log(e.message);
        }
      },
    },
  };
</script>
