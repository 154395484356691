<template>
  <div>
    <b-tabs content-class="mt-3" fill>
      <b-tab title="Pending" @click="getPrePMG('pending')" active>
        <div class="status-header">
          <h1>Pending</h1>
          <div class="function-bar">
            <div class="display">
              <span>{{ $t("Show Per Page") }} : </span>
              <button @click="perPage = 5">5</button>
              <button @click="perPage = 20">20</button>
              <button @click="perPage = 50">50</button>
              <button @click="perPage = 100">100</button>
            </div>
            <div class="search-section">
              <input type="text" v-model="searchWord" />
              <button class="search-button" @click="searchContact">
                Search
              </button>
              <button class="search-button" @click="getPrePMG('pending')">
                Reset
              </button>
            </div>
          </div>
        </div>
        <b-table
          :items="PMGData"
          :per-page="0"
          :current-page="currentPage"
          :fields="fields"
          show-empty
          small
          responsive
          empty-filtered-text="No Data"
          class="PMG-table"
          id="pmg-table"
        >
          <template #cell(id)="row">
            {{ row.item.id }}
          </template>
          <template #cell(email)="row">
            {{ row.item.email }}
          </template>
          <template #cell(name)="row">
            {{ row.item.name }}
          </template>
          <template #cell(register_at)="row">
            {{ row.item.register_at }}
          </template>
          <template #cell(total_collected)="row">
            {{ row.item.total_collected }}
          </template>
          <template #cell(action)="row">
            <div>
              <div class="button-section">
                <b-button
                  class="b-button button update"
                  @click="storeItem(row.item)"
                  v-b-modal.modal-prevent-closing
                >
                  {{ $t("Edit") }}
                </b-button>
                <button
                  class="b-button button update"
                  @click="confirmMessage(row.item)"
                >
                  {{ $t("Delete") }}
                </button>
              </div>
            </div>
          </template>
          <template #empty="scope">
            <p class="text-center">{{ (scope.emptyText = "No Data") }}</p>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          aria-controls="pmg-table"
          align="center"
        ></b-pagination>
      </b-tab>
      <b-tab title="Complete" @click="getPrePMG('completed')">
        <div class="status-header">
          <h1>Complete</h1>
          <div class="function-bar">
            <div class="display">
              <span>{{ $t("Show Per Page") }} : </span>
              <button @click="perPage = 5">5</button>
              <button @click="perPage = 20">20</button>
              <button @click="perPage = 50">50</button>
              <button @click="perPage = 100">100</button>
            </div>
            <div class="search-section">
              <input type="text" v-model="searchWord" />
              <button class="search-button" @click="searchContact">
                Search
              </button>
              <button class="search-button" @click="getPrePMG('completed')">
                Reset
              </button>
            </div>
          </div>
        </div>
        <b-table
          :items="PMGData"
          :per-page="0"
          :current-page="currentPage"
          :fields="completefields"
          show-empty
          small
          responsive
          empty-filtered-text="No Data"
          class="PMG-table"
          id="pmg-table"
        >
          <template #cell(id)="row">
            {{ row.item.id }}
          </template>
          <template #cell(email)="row">
            {{ row.item.email }}
          </template>
          <template #cell(name)="row">
            {{ row.item.name }}
          </template>
          <template #cell(register_at)="row">
            {{ row.item.register_at }}
          </template>
          <template #cell(total_collected)="row">
            {{ row.item.total_collected }}
          </template>
          <template #empty="scope">
            <p class="text-center">{{ (scope.emptyText = "No Data") }}</p>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          aria-controls="pmg-table"
          align="center"
        ></b-pagination>
      </b-tab>
    </b-tabs>
    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      @hidden="resetModal"
      @ok.prevent="editPMG"
      title="Edit PMG"
    >
      <form ref="form" @submit.stop.prevent="editPMG">
        <p>ID : {{ this.item.id }}</p>
        <b-form-group
          label="Pioneer Address"
          label-for="pioneerAddress"
          :state="nameState"
          invalid-feedback="Pioneer Address is required."
        >
          <b-form-input
            id="pioneerAddress"
            v-model="pioneer_address"
            :state="nameState"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="PMG Cert."
          label-for="pmgCert"
          :state="nameState"
          invalid-feedback="PMG Cert is required."
        >
          <b-form-input
            id="pmgCert"
            v-model="pmg_cert"
            :state="nameState"
            required
          ></b-form-input>
        </b-form-group>
        <div class="error">
          {{ this.editPMGError && this.editPMGError }}
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
  import ABI from "../assets/ABI/AdminContract.json";
  var axios = require("axios");
  const { ethers } = require("ethers");
  const provider = new ethers.providers.Web3Provider(window.ethereum);

  export default {
    name: "RunnerRecord",
    data() {
      return {
        searchWord: "",
        fields: [
          { key: "id", label: "ID" },
          { key: "pioneer_address", label: "Pioneer Address" },
          { key: "pmg_cert", label: "PMG Cert." },
          { key: "phone_number", label: "Owner Phone Number" },
          { key: "email", label: "Agent Email" },
          //   { key: "name", label: "Name" },
          { key: "action", label: "Action" },
        ],
        completefields: [
          { key: "id", label: "ID" },
          { key: "pioneer_address", label: "Pioneer Address" },
          { key: "pmg_cert", label: "PMG Cert." },
          { key: "phone_number", label: "Owner Phone Number" },
          { key: "email", label: "Agent Email" },
          //   { key: "name", label: "Name" },
        ],
        currentPage: 1,
        completeCurrentPage: 1,
        cancelCurrentPage: 1,
        totalRows: null,
        perPage: 20,
        url: process.env.VUE_APP_API_URL,
        PMGData: [],
        signer: "",
        contract: "",
        ownerAddress: "",
        contractAddress: process.env.VUE_APP_CONTRACT_ADDRESS,
        contractABI: ABI,
        status: "pending",
        authorisedAddress: [],
        pioneer_address: "",
        pmg_cert: "",
        item: "",
        editPMGError: "",
        nameState: null,
      };
    },
    watch: {
      perPage: {
        handler() {
          this.getPrePMG(this.status);
        },
        deep: true,
      },
      currentPage: {
        handler() {
          this.getPrePMG(this.status);
        },
        deep: true,
      },
    },
    mounted: async function () {
      if (window.localStorage.getItem("address")) {
        try {
          const signer = provider.getSigner(); //user
          this.signer = await signer.getAddress();
          this.contract = new ethers.Contract(
            this.contractAddress,
            this.contractABI,
            signer
          );
          // this.ownerAddress = await this.contract.owner();
        } catch (e) {
          console.log(e.message);
        }
      }
      this.getAdmin();
      this.getPrePMG("pending");
    },
    methods: {
      reloadPage() {
        window.location.reload();
      },
      getAdmin() {
        if (this.contract) {
          try {
            axios({
              method: "get",
              url: this.url + "api/admin/",
              // headers: { ...data.getHeaders() },
            }).then((response) => {
              response.data.map((data) => {
                if (data.status === true) {
                  this.authorisedAddress.push(data);
                }
              });
              // this.authorisedAddress = response.data;
              // this.authorisedAddress.push({ address: this.ownerAddress });
            });
          } catch (e) {
            console.log("error", e.message);
          }
        }
      },
      getPrePMG(status) {
        this.status = status;
        try {
          axios({
            method: "get",
            url:
              this.url +
              `api/runner/prepmg?pageSize=${this.perPage}&page=${this.currentPage}&status=${status}`,
            // headers: { ...data.getHeaders() },
          }).then((response) => {
            this.PMGData = response.data.results;
            this.totalRows = response.data.metadata.total;
          });
        } catch (e) {
          console.log("error", e.message);
        }
      },
      searchContact() {
        try {
          axios({
            method: "post",
            url:
              this.url +
              `api/runner/update/prepmg?pageSize=${this.perPage}&page=${this.currentPage}&contact_number=${this.searchWord}&status=${this.status}`,
            // headers: { ...data.getHeaders() },
          }).then((response) => {
            this.PMGData = response.data.results;
            this.totalRows = response.data.metadata.total;
          });
        } catch (e) {
          console.log("error", e.message);
        }
      },
      updatePMGStatus(id) {
        let success = false;
        for (let i = 0; i < this.authorisedAddress.length; i++) {
          if (this.authorisedAddress[i].address == this.signer) {
            success = true;
            try {
              axios({
                method: "post",
                url: this.url + `api/runner/update/prepmg`,
                headers: {
                  Authorization: "Basic YWRtaW46TExEUE54X1pxOTQ/QWEhUw==",
                },
                data: {
                  id: id,
                },
              }).then(() => {
                this.showAlert("success", "Success Delete", 0);
              });
            } catch (e) {
              this.showAlert("error", "Delete Failed.", 1);
              console.log("error", e.message);
            }
          }
        }
        if (success == false) {
          this.showAlert("error", "Not authorised for this action.", 1);
        }
      },
      editPMG() {
        console.log("??");
        let success = false;
        for (let i = 0; i < this.authorisedAddress.length; i++) {
          if (this.authorisedAddress[i].address == this.signer) {
            success = true;
            try {
              axios({
                method: "post",
                url: this.url + `api/runner/edit/prepmg`,
                headers: {
                  Authorization: "Basic YWRtaW46TExEUE54X1pxOTQ/QWEhUw==",
                },
                data: {
                  id: this.item.id,
                  pioneer_address: this.pioneer_address,
                  pmg_cert: this.pmg_cert,
                },
              }).then(() => {
                this.showAlert("success", "Success Update", 0);
              });
            } catch (e) {
              this.showAlert("error", "Update Failed.", 1);
              console.log("error", e.message);
            }
          }
        }
        if (success == false) {
          this.showAlert("error", "Not authorised for this action.", 1);
        }
      },
      showAlert(type, title, state) {
        this.$swal
          .fire({
            position: "center",
            icon: type,
            title: title,
            showConfirmButton: true,
            backdrop: true,
            allowOutsideClick: false,
          })
          .then((result) => {
            if (result.isConfirmed && state == 0) {
              this.reloadPage();
            }
          });
      },
      confirmMessage(item) {
        const h = this.$createElement;
        this.messageVNodeList = h("div", { class: ["foobar"] }, [
          h("div", { class: ["text-start"] }, [
            h("p", { class: ["text-title"] }, ["ID"]),
            h("div", { class: ["text-data"] }, [
              h("p", { class: ["text-start"] }, [" : ", item.id]),
            ]),
          ]),
          h("div", { class: ["text-start"] }, [
            h("p", { class: ["text-title"] }, ["Pioneer Address"]),
            h("div", { class: ["text-data"] }, [
              h("p", { class: ["text-start"] }, [" : ", item.pioneer_address]),
            ]),
          ]),
          h("div", { class: ["text-start"] }, [
            h("p", { class: ["text-title"] }, ["Pmg Cert."]),
            h("div", { class: ["text-data"] }, [
              h("p", { class: ["text-start"] }, [" : ", item.pmg_cert]),
            ]),
          ]),
          h("div", { class: ["text-start"] }, [
            h("p", { class: ["text-title"] }, ["Owner Phone Number"]),
            h("div", { class: ["text-data"] }, [
              h("p", { class: ["text-start"] }, [" : ", item.phone_number]),
            ]),
          ]),
          h("div", { class: ["text-start"] }, [
            h("p", { class: ["text-title"] }, ["Agent Email"]),
            h("div", { class: ["text-data"] }, [
              h("p", { class: ["text-start"] }, [" : ", item.email]),
            ]),
          ]),
        ]);

        this.$bvModal
          .msgBoxConfirm(this.messageVNodeList, {
            title: "Are you Sure?",
            okTitle: "OK",
            cancelTitle: "Cancel",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value == true) {
              this.updatePMGStatus(item.id);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      checkFormValidity() {
        const valid = this.$refs.form.checkValidity();
        this.nameState = valid;
        return valid;
      },
      resetModal() {
        this.item = "";
        this.pioneer_address = "";
        this.pmg_cert = "";
      },
      storeItem(item) {
        this.item = item;
        this.pioneer_address = item.pioneer_address;
        this.pmg_cert = item.pmg_cert;
      },
    },
  };
</script>
