<template>
  <div class="home">
    <div class="balance-table">
      <h1>{{ $t("PMG Minting") }}</h1>
      <h1>${{ balance }}</h1>
      <div class="coin-balance">
        <div class="USDT" v-bind:class="{ isSelected: usdtSelected }">
          <img src="../assets/images/USDT.png" />
          <div>{{ coinList[0].name }} (BEP20)</div>
          <div>${{ coinList[0].amount }}</div>
        </div>
        <div class="USDC" v-bind:class="{ isSelected: usdcSelected }">
          <img src="../assets/images/USDC.png" />
          <div>{{ coinList[1].name }} (BEP20)</div>
          <div>${{ coinList[1].amount }}</div>
        </div>
        <div class="BUSD" v-bind:class="{ isSelected: busdSelected }">
          <img src="../assets/images/BUSD.png" />
          <div>{{ coinList[2].name }} (BEP20)</div>
          <div>${{ coinList[2].amount }}</div>
        </div>
        <!-- <div class="DAI" v-bind:class="{ isSelected: daiSelected }">
          <img src="../assets/images/DAI.png" />
          <div>{{ coinList[3].name }} (BEP20)</div>
          <div>${{ coinList[3].amount }}</div>
        </div> -->
      </div>
      <!-- <form class="withdraw-table" @submit.prevent="withdraw">
        <div>{{ $t("Select Coin to Withdraw") }}</div>
        <select v-model="coinId">
          <option value="1">USDT</option>
          <option value="2">USDC</option>
          <option value="3">BUSD</option>
          <option value="4">DAI</option>
        </select>
        <button type="submit">
          {{ $t("Withdraw") }}
        </button>
      </form> -->
      <div class="error">{{ withdrawError && withdrawError }}</div>
    </div>
    <div class="balance-table">
      <h1>{{ $t("Mystery Minting") }}</h1>
      <h1>${{ Mbalance }}</h1>
      <div class="coin-balance">
        <div class="USDT" v-bind:class="{ isSelected: usdtSelected }">
          <img src="../assets/images/USDT.png" />
          <div>{{ coinListM[0].name }} (BEP20)</div>
          <div>${{ coinListM[0].amount }}</div>
        </div>
        <div class="USDC" v-bind:class="{ isSelected: usdcSelected }">
          <img src="../assets/images/USDC.png" />
          <div>{{ coinListM[1].name }} (BEP20)</div>
          <div>${{ coinListM[1].amount }}</div>
        </div>
        <div class="BUSD" v-bind:class="{ isSelected: busdSelected }">
          <img src="../assets/images/BUSD.png" />
          <div>{{ coinListM[2].name }} (BEP20)</div>
          <div>${{ coinListM[2].amount }}</div>
        </div>
      </div>
    </div>
    <div class="balance-table">
      <h1>{{ $t("Marketplace Profit Distribution") }}</h1>
      <div class="profit-table">
        <div>
          <h5>Development team 8.5%</h5>
          <div>
            <div>
              <span>USDT : </span><span>{{ this.development[0] }}</span>
            </div>
            <div>
              <span>USDC : </span><span>{{ this.development[1] }}</span>
            </div>
            <div>
              <span>BUSD : </span><span>{{ this.development[2] }}</span>
            </div>
            <div>
              <span>WBNB : </span><span>{{ this.development[3] }}</span>
            </div>
            <div>
              <span>WETH : </span><span>{{ this.development[4] }}</span>
            </div>
          </div>
        </div>
        <!-- <div>
          <h5>Marketplace 2.5%</h5>
          <div>
            <div>
              <span>USDT : </span><span>{{ this.marketplace[0] }}</span>
            </div>
            <div>
              <span>USDC : </span><span>{{ this.marketplace[1] }}</span>
            </div>
            <div>
              <span>BUSD : </span><span>{{ this.marketplace[2] }}</span>
            </div>
            <div>
              <span>WBNB : </span><span>{{ this.marketplace[3] }}</span>
            </div>
            <div>
              <span>WETH : </span><span>{{ this.marketplace[4] }}</span>
            </div>
          </div>
        </div>
        <div>
          <h5>Creator 1%</h5>
          <div>
            <div>
              <span>USDT : </span><span>{{ this.creator[0] }}</span>
            </div>
            <div>
              <span>USDC : </span><span>{{ this.creator[1] }}</span>
            </div>
            <div>
              <span>BUSD : </span><span>{{ this.creator[2] }}</span>
            </div>
            <div>
              <span>WBNB : </span><span>{{ this.creator[3] }}</span>
            </div>
            <div>
              <span>WETH : </span><span>{{ this.creator[4] }}</span>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div>
      <div class="whitelist-form">
        <h1>{{ $t("Add Authorised Address") }}</h1>
        <form @submit.prevent="whitelist">
          <div class="form-table">
            <label for="address">{{ $t("Address") }} : </label>
            <textarea v-model="address" id="address" required></textarea>
          </div>
          <button type="submit" class="whitelist-button">
            {{ $t("Submit") }}
          </button>
          <div class="error">{{ whitelistError && whitelistError }}</div>
        </form>
      </div>
      <b-table
        :items="whitelistAddress"
        :per-page="perPage"
        :current-page="currentPage"
        :fields="fields"
        show-empty
        small
        responsive
        empty-filtered-text="No Data"
        id="whitelist-table"
      >
        <template #cell(index)="data">
          <div>
            <div>
              {{ data.index + 1 }}
            </div>
          </div>
        </template>
        <template #cell(address)="row">
          <div>
            <div>
              {{ row.item.address }}
            </div>
          </div>
        </template>
        <template #cell(name)="row">
          <div>
            <div>
              {{ row.item.name }}
            </div>
          </div>
        </template>
        <template #cell(status)="row">
          <div>
            <div>
              {{ row.item.status == true ? "Authorised" : "Not Authorised" }}
            </div>
          </div>
        </template>
        <template #cell(action)="row">
          <div>
            <div class="admin-table-action">
              <button
                class="b-button button update"
                @click="selectedAddress = row.item.address"
                v-b-modal.modal-prevent-closing
              >
                Update Name
              </button>
              <button
                class="b-button button update"
                @click="changeAuthority(row.item.address)"
              >
                Change Authority
              </button>
            </div>
          </div>
        </template>
        <template #empty="scope">
          <p class="text-center">{{ (scope.emptyText = "No Data") }}</p>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        aria-controls="whitelist-table"
        align="center"
      ></b-pagination>
      <b-modal
        id="modal-prevent-closing"
        ref="modal"
        @show="resetModal"
        @hidden="resetModal"
        @ok.prevent="updateName"
        title="Edit Category"
      >
        <form ref="form" @submit.stop.prevent="updateName">
          <p>Address : {{ selectedAddress }}</p>
          <b-form-group
            label="Nickname"
            label-for="nickname"
            :state="nameState"
            invalid-feedback="Nickname is required."
          >
            <b-form-input
              id="nickname"
              v-model="nickname"
              :state="nameState"
              required
            ></b-form-input>
          </b-form-group>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
  // import Vue from "vue";
  // import VueSweetalert2 from "vue-sweetalert2";
  // import "sweetalert2/dist/sweetalert2.min.css";

  // Vue.use(VueSweetalert2);
  import ABI from "../assets/ABI/AdminContract.json";
  // import token from "../assets/ABI/tokenContract.json";
  // import bnb from "../assets/ABI/bnbContract.json";
  import token from "../assets/ABI/tokenStrat.json";

  const { ethers } = require("ethers");
  var axios = require("axios");
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  import { Contract, Provider } from "ethers-multicall";
  const Decimal = require("decimal.js");

  export default {
    name: "Home",
    data() {
      return {
        balance: 0,
        Mbalance: 0,
        signer: "",
        ownerAddress: "",
        selectedAddress: "",
        contractABI: ABI,
        tokenABI: token,
        // bnbABI: bnb,
        address: "",
        coinId: 1,
        withdrawError: "",
        contract: "",
        usdtSelected: "",
        usdcSelected: "",
        busdSelected: "",
        daiSelected: "",
        whitelistAddress: [],
        perPage: "5",
        currentPage: "1",
        totalRows: "",
        whitelistError: "",
        contractAddress: process.env.VUE_APP_CONTRACT_ADDRESS,
        // usdtAddress: process.env.VUE_APP_USDT_ADDRESS,
        // usdcAddress: process.env.VUE_APP_USDC_ADDRESS,
        // daiAddress: process.env.VUE_APP_DAI_ADDRESS,
        // busdAddress: process.env.VUE_APP_BUSD_ADDRESS,
        usdtBalance: 0,
        usdcBalance: 0,
        busdBalance: 0,
        daiBalance: 0,
        fields: [
          "index",
          { key: "address", label: "Admin Address" },
          { key: "name", label: "Nickname" },
          { key: "status", label: "Status" },
          { key: "action", label: "Action" },
        ],
        nickname: "",
        chosenAddress: "",
        nameState: null,
        coinList: "",
        coinListM: "",
        url: process.env.VUE_APP_API_URL,
        wbnbAddress: process.env.VUE_APP_WBNB_ADDRESS,
        usdtAddress: process.env.VUE_APP_USDT_ADDRESS,
        busdAddress: process.env.VUE_APP_BUSD_ADDRESS,
        usdcAddress: process.env.VUE_APP_USDC_ADDRESS,
        wethAddress: process.env.VUE_APP_WETH_ADDRESS,
        creatorAddress: process.env.VUE_APP_CREATOR_ADDRESS,
        development: [],
      };
    },
    mounted: async function () {
      if (window.localStorage.getItem("address")) {
        try {
          const signer = provider.getSigner(); //user
          this.signer = await signer.getAddress();
          this.contract = new ethers.Contract(
            this.contractAddress,
            this.contractABI,
            signer
          );
          this.ownerAddress = await this.contract.owner();
          this.getProfitDistribution();
        } catch (e) {
          console.log(e);
        }
      }

      try {
        axios({
          method: "get",
          url: this.url + "api/admin/balance",
          headers: {
            "Content-Type": "application/json",
          },
        }).then((response) => {
          this.coinList = response.data;
          this.fetchTokenBalance("1");
        });

        axios({
          method: "get",
          url: this.url + "api/admin",
          headers: {
            "Content-Type": "application/json",
          },
        }).then(
          (response) => (
            (this.whitelistAddress = response.data),
            (this.totalRows = response.data.length)
          )
        );

        axios({
          method: "get",
          url: this.url + "api/admin/mbone_balance",
          headers: {
            "Content-Type": "application/json",
          },
        }).then((response) => {
          this.coinListM = response.data;
          this.fetchTokenBalance("2");
        });
      } catch (e) {
        console.log(e);
      }
    },
    methods: {
      connectWallet() {
        this.$emit("connect");
      },
      async whitelist() {
        let success = false;
        if (this.contract) {
          if (ethers.utils.isAddress(this.address)) {
            if (this.signer == this.ownerAddress) {
              success = true;
              try {
                const editAdmin = await this.contract.editAdmin(this.address);

                const receipt = await provider.waitForTransaction(
                  editAdmin.hash
                );

                if (!receipt) {
                  this.showAlert("error", "Change Admin Failed!", 1);
                } else {
                  this.showAlert("success", "Change Admin Success!", 0);
                }
              } catch (e) {
                this.showAlert("error", "Change Admin Failed!", 1);
                console.log("error", e.message);
              }
              this.whitelistError = "";
            }
            if (success == false) {
              this.whitelistError = "Not authorised for this action";
            }
          } else {
            this.whitelistError = "The input is not an address.";
          }
        }
      },
      checkFormValidity() {
        const valid = this.$refs.form.checkValidity();
        this.nameState = valid;
        return valid;
      },
      resetModal() {
        this.nickname = "";
        this.nameState = null;
      },
      storeAddress(address) {
        this.selectedAddress = address;
      },
      showAlert(type, title, state) {
        this.$swal
          .fire({
            position: "center",
            icon: type,
            title: title,
            showConfirmButton: true,
            backdrop: true,
            allowOutsideClick: false,
          })
          .then((result) => {
            if (result.isConfirmed && state == 0) {
              window.location.reload();
            }
          });
      },
      showLoading() {
        this.$swal.fire({
          position: "center",
          title: "Processing...",
          showConfirmButton: false,
          backdrop: true,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
        });
      },
      async updateName() {
        if (!this.checkFormValidity()) {
          return;
        }
        if (this.signer == this.ownerAddress) {
          try {
            axios({
              method: "post",
              url:
                this.url +
                `api/admin/update/name?address=${this.selectedAddress}&name=${this.nickname}`,
              headers: {
                "Content-Type": "application/json",
                Authorization: "Basic YWRtaW46TExEUE54X1pxOTQ/QWEhUw==",
              },
            }).then(this.showAlert("success", "Update Name Success!", 0));
          } catch (e) {
            this.showAlert("error", "Update Failed.", 1);
            console.log(e.message);
          }
        } else {
          this.showAlert("error", "Not Authorised for this action.", 1);
        }
      },
      async changeAuthority(address) {
        let success = false;
        if (this.contract) {
          if (this.signer == this.ownerAddress) {
            success = true;
            try {
              const editAdmin = await this.contract.editAdmin(address);

              const receipt = await provider.waitForTransaction(editAdmin.hash);

              if (!receipt) {
                this.$swal.fire("Failed", "Change Admin Failed!", "error");
              } else {
                this.$swal
                  .fire("Success", "Change Admin Success!", "success")
                  .then((result) => {
                    if (result.isConfirmed) {
                      window.location.reload();
                    }
                  });
              }
            } catch (e) {
              this.$swal.fire("Failed", "Change Admin Failed!", "error");
              console.log("error", e.message);
            }
            this.whitelistError = "";
          }
          if (success == false) {
            this.whitelistError = "Not authorised for this action";
          }
        }
      },
      async withdraw() {
        if (this.ownerAddress == this.signer) {
          this.$bvModal
            .msgBoxConfirm("Are you sure you want to withdraw?")
            .then(async (value) => {
              if (value == true) {
                try {
                  const withdraw = await this.contract.withdawToken(
                    this.coinId
                  );
                  const receipt = await provider.waitForTransaction(
                    withdraw.hash
                  );

                  if (!receipt) {
                    this.$swal.fire("Failed", "Withdraw Failed!", "error");
                    // this.whitelistError = "Whitelist failed.";
                  } else {
                    this.$swal
                      .fire("Success", "Withdraw Success!", "success")
                      .then((result) => {
                        if (result.isConfirmed) {
                          window.location.reload();
                        }
                      });
                  }
                } catch (e) {
                  console.log(e.message);
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.withdrawError = "Not authorised to withdraw.";
        }
      },
      fetchTokenBalance(type) {
        if (type == 1) {
          this.coinList &&
            this.coinList.map((coin) => {
              this.balance += parseInt(coin.amount);
            });
        } else {
          this.coinListM &&
            this.coinListM.map((coin) => {
              this.Mbalance += parseInt(coin.amount);
            });
        }
      },
      formatNumber(num) {
        let formatedNumber = (num / 1e18).toString();
        return formatedNumber;
      },
      async getProfitDistribution() {
        try {
          const ethcallProvider = new Provider(
            new ethers.providers.Web3Provider(window.ethereum)
          );
          await ethcallProvider.init();
          let usdtContract = new Contract(this.usdtAddress, this.tokenABI);
          let usdcContract = new Contract(this.usdcAddress, this.tokenABI);
          let busdContract = new Contract(this.busdAddress, this.tokenABI);
          let wethContract = new Contract(this.wethAddress, this.tokenABI);
          let wbnbContract = new Contract(this.wbnbAddress, this.tokenABI);

          //Development
          // const wbnbDevCall = wbnbContract.balanceOf(this.devAddress);
          // const usdtDevCall = usdtContract.balanceOf(this.devAddress);
          // const usdcDevCall = usdcContract.balanceOf(this.devAddress);
          // const busdDevCall = busdContract.balanceOf(this.devAddress);
          // const wethDevCall = wethContract.balanceOf(this.devAddress);

          let [wbnbDev, usdtDev, usdcDev, busdDev, wethDev] =
            await ethcallProvider.all([
              wbnbContract.userInfos(this.creatorAddress),
              usdtContract.userInfos(this.creatorAddress),
              usdcContract.userInfos(this.creatorAddress),
              busdContract.userInfos(this.creatorAddress),
              wethContract.userInfos(this.creatorAddress),
            ]);
          console.log(wbnbDev, usdtDev, usdcDev, busdDev, wethDev);

          this.development.push(
            new Decimal(
              ethers.utils.formatEther(usdtDev.want).toString()
            ).toFixed(2, Decimal.ROUND_DOWN),
            new Decimal(
              ethers.utils.formatEther(usdcDev.want).toString()
            ).toFixed(2, Decimal.ROUND_DOWN),
            new Decimal(
              ethers.utils.formatEther(busdDev.want).toString()
            ).toFixed(2, Decimal.ROUND_DOWN),
            new Decimal(
              ethers.utils.formatEther(wbnbDev.want).toString()
            ).toFixed(4, Decimal.ROUND_DOWN),
            new Decimal(
              ethers.utils.formatEther(wethDev.want).toString()
            ).toFixed(4, Decimal.ROUND_DOWN)
          );
        } catch (e) {
          console.log(e);
        }
      },
    },
  };
</script>
<style>
  .home {
    color: white;
    margin: 20px 0px;
  }

  .USDT,
  .USDC,
  .BUSD,
  .DAI {
    padding: 10px;
  }

  .USDT > img,
  .USDC > img,
  .BUSD > img,
  .DAI > img {
    margin-bottom: 10px;
  }

  .isSelected {
    border: 2px solid #5454d0 !important;
  }

  .balance-table,
  .whitelist-form {
    background-color: rgba(211, 211, 211, 0.1);
    box-shadow: 2px 2px 10px 10px #5454d0;
    width: 90%;
    margin: 0 auto;
    padding: 20px 0px;
    margin-bottom: 50px;
  }

  .coin-balance {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    font-size: 1.2em;
  }

  .coin-balance > div {
    background-color: rgba(211, 211, 211, 0.2);
    width: 30%;
    border: 1px solid white;
    border-radius: 20px;
  }

  .withdraw-table {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.2em;
    margin: 20px 0px;
  }

  .withdraw-table > select {
    width: 20%;
    padding: 5px;
  }

  .withdraw-table > select,
  .withdraw-table > button,
  .whitelist-button {
    border-radius: 30px;
  }

  .withdraw-table > button,
  .whitelist-button {
    border: 1px solid #5454d0;
    background: linear-gradient(to bottom, #5454d0 100%, #2a25b5 100%);
    color: white;
    text-shadow: 0px 1px #000000;
    padding: 5px 10px;
    margin-top: 10px;
    background-size: 200% 200%;
    font-size: 1em;
  }

  .withdraw-table > button:hover,
  .whitelist-button:hover {
    opacity: 0.8;
  }

  .whitelist-form > form > .form-table {
    display: flex;
    flex-direction: row;
    font-size: 1.2em;
    justify-content: space-between;
    width: 80%;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  textarea {
    display: flex;
    justify-content: flex-end;
    border-radius: 10px;
    border: none;
    padding: 3px 15px;
    width: 70%;
    height: 40px;
    font-size: 0.8em;
  }

  .admin-table-action {
    display: flex;
    flex-direction: row;
  }

  .profit-table {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 0 auto;
  }

  .profit-table div {
    width: 100%;
  }

  .profit-table > div > div {
    display: flex;
    flex-direction: column;
    width: 30%;
    margin: 0 auto;
  }

  .profit-table > div > div > div {
    display: flex;
    flex-direction: row;
  }

  .profit-table > div > div > div > span {
    width: 50%;
    text-align: center;
  }
</style>
