<template>
  <div>
    <div>
      <b-tabs content-class="mt-3" fill>
        <b-tab title="Without Thumbnail" active @click="getPMG(0, currentPage)">
          <div class="status-header">
            <h1>Without Thumbnail</h1>
            <div class="function-bar">
              <div class="display">
                <span>{{ $t("Show Per Page") }} : </span>
                <button @click="perPage = 5">5</button>
                <button @click="perPage = 20">20</button>
                <button @click="perPage = 50">50</button>
                <button @click="perPage = 100">100</button>
              </div>
            </div>
          </div>
          <b-table
            :items="thumbnailData"
            :per-page="0"
            :current-page="currentPage"
            :fields="withoutfields"
            show-empty
            small
            responsive
            empty-filtered-text="No Data"
            class="PMG-table"
            id="xThumbnail-table"
          >
            <!-- <template #cell(current_owner)="row">
              <div>
                <div>{{ row.item.current_owner }}</div>
              </div>
            </template> -->
            <template #cell(pioneer_address)="row">
              <div>
                <div>{{ row.item.pioneer_address }}</div>
              </div>
            </template>
            <template #empty="scope">
              <p class="text-center">{{ (scope.emptyText = "No Data") }}</p>
            </template>
            <template #cell(pmg_cert)="row">
              <div>
                <div>{{ row.item.pmg_cert }}</div>
              </div>
            </template>
            <template #cell(created_at)="row">
              <div>
                <div>{{ getDate(row.item.created_at) }}</div>
              </div>
            </template>
            <template #cell(thumbnail)="row">
              <div class="thumbnail-status">
                <div v-if="row.item.thumbnail">
                  <div>
                    <b-icon-check-square scale="2" variant="success" />
                  </div>
                </div>
                <div v-else>
                  <div>
                    <b-icon-x-square scale="2" variant="danger" />
                  </div>
                </div>
              </div>
            </template>
            <template #cell(action)="row">
              <div>
                <div v-if="!row.item.thumbnail">
                  <b-button
                    class="b-button button update"
                    v-b-modal.modal-prevent-closing
                    @click="
                      storeData(
                        row.item.id,
                        row.item.pioneer_address,
                        row.item.pmg_cert
                      )
                    "
                    >{{ $t("Update") }}</b-button
                  >
                </div>
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="xThumbnail-table"
            align="center"
          ></b-pagination>
        </b-tab>
        <b-tab title="With Thumbnail" @click="getPMG(1, currentPage)">
          <div class="status-header">
            <h1>With Thumbnail</h1>
            <div class="function-bar">
              <div class="display">
                <span>{{ $t("Show Per Page") }} : </span>
                <button @click="perPage = 5">5</button>
                <button @click="perPage = 20">20</button>
                <button @click="perPage = 50">50</button>
                <button @click="perPage = 100">100</button>
              </div>
            </div>
          </div>
          <b-table
            :items="thumbnailData"
            :per-page="perPage"
            :current-page="currentPage"
            :fields="withfields"
            show-empty
            small
            responsive
            empty-filtered-text="No Data"
            class="PMG-table"
            id="thumbnail-table"
          >
            <!-- <template #cell(current_owner)="row">
              <div>
                <div>{{ row.item.current_owner }}</div>
              </div>
            </template> -->
            <template #cell(pioneer_address)="row">
              <div>
                <div>{{ row.item.pioneer_address }}</div>
              </div>
            </template>
            <template #empty="scope">
              <p class="text-center">{{ (scope.emptyText = "No Data") }}</p>
            </template>
            <template #cell(pmg_cert)="row">
              <div>
                <div>{{ row.item.pmg_cert }}</div>
              </div>
            </template>
            <template #cell(created_at)="row">
              <div>
                <div>{{ getDate(row.item.created_at) }}</div>
              </div>
            </template>
            <template #cell(thumbnail)="row">
              <div class="thumbnail-status">
                <div v-if="row.item.thumbnail">
                  <div>
                    <b-icon-check-square scale="2" variant="success" />
                  </div>
                </div>
                <div v-else>
                  <div>
                    <b-icon-x-square scale="2" variant="danger" />
                  </div>
                </div>
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="thumbnail-table"
            align="center"
          ></b-pagination>
        </b-tab>
      </b-tabs>
    </div>
    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      @show="resetModal"
      @hidden="resetModal"
      @ok.prevent="addThumbnail"
      title="Add GIF File"
    >
      <form ref="form" @submit.stop.prevent="addThumbnail">
        <div class="d-flex">
          <b-form-group
            label=""
            label-for="thumbnail"
            :state="nameState"
            invalid-feedback="Thumbnail is required"
          >
            <div>
              <video
                width="500px"
                height="400px"
                muted
                autoplay
                :src="this.thumbnailurl && this.thumbnailurl"
                type="video/mp4"
              ></video>
            </div>
            <input type="file" @change="onThumbnailFileChange" />
            <div class="error">
              {{ thumbnailError && thumbnailError }}
            </div>
          </b-form-group>
          <br />

          <b-form-group
            label=""
            label-for="thumbnailPic"
            :state="nameState"
            invalid-feedback="Thumbnail pic is required"
          >
            <div>
              <img
                width="500px"
                height="400px"
                v-if="thumbnailPicUrl"
                :src="this.thumbnailPicUrl"
                alt="thumbnail"
              />
            </div>
            <input type="file" @change="onThumbnailPicChange" />
            <div class="error">
              {{ thumbnailPicError && thumbnailPicError }}
            </div>
          </b-form-group>
          <br />
        </div>

        <span>{{ $t("ID") }}:{{ updateID }}</span
        ><br />
        <span>{{ $t("Pioneer Address") }} : {{ updateAddress }}</span
        ><br />
        <span>{{ $t("PMG Cert") }} : {{ updateCert }}</span>
        <div class="error">
          {{ addThumbnailError && addThumbnailError }}
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
  // import Vue from "vue";
  // import VueSweetalert2 from "vue-sweetalert2";
  // import "sweetalert2/dist/sweetalert2.min.css";

  // Vue.use(VueSweetalert2);
  var axios = require("axios");
  var moment = require("moment");
  import ABI from "../assets/ABI/AdminContract.json";
  const { ethers } = require("ethers");
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  // import { BIconXSquare, BIconCheckSquare } from "bootstrap-vue";
  import "bootstrap-vue/dist/bootstrap-vue-icons.min.css";

  export default {
    name: "MintStatus",
    data() {
      return {
        signer: "",
        type: 0,
        perPage: "20",
        thumbnailData: "",
        withoutfields: [
          // { key: "current_owner", label: "Current Owner" },
          { key: "pioneer_address", label: "Pioneer Address" },
          { key: "pmg_cert", label: "PMG Cert" },
          { key: "created_at", label: "Created At", formatter: this.getDate },
          {
            key: "thumbnail",
            label: "Thumbnail",
            formatter: this.checkThumbnail,
          },
          {
            key: "action",
            label: "Action",
          },
        ],
        withfields: [
          { key: "token_id", label: "ID" },
          { key: "pioneer_address", label: "Pioneer Address" },
          { key: "pmg_cert", label: "PMG Cert" },
          { key: "created_at", label: "Created At", formatter: this.getDate },
          {
            key: "thumbnail",
            label: "Thumbnail",
            formatter: this.checkThumbnail,
          },
        ],
        searchWord: "",
        contractABI: ABI,
        currentPage: 1,
        totalRows: "",
        thumbnailurl: "",
        thumbnailVideo: "",
        thumbnailError: "",
        addThumbnailError: "",
        thumbnailPic: "",
        thumbnailPicUrl: "",
        thumbnailPicError: "",
        thumbnailPicImage: "",
        nameState: null,
        updateID: "",
        updateAddress: "",
        updateCert: "",
        authorisedAddress: [],
        contractAddress: process.env.VUE_APP_CONTRACT_ADDRESS,
        ownerAddress: "",
        validImageTypes: ["jpg", "jpeg", "png"],
        url: process.env.VUE_APP_API_URL,
      };
    },
    mounted: async function () {
      if (window.localStorage.getItem("address")) {
        try {
          const signer = provider.getSigner(); //user
          this.signer = await signer.getAddress();
          this.contract = new ethers.Contract(
            this.contractAddress,
            this.contractABI,
            signer
          );
          // this.ownerAddress = await this.contract.owner();
        } catch (e) {
          console.log(e.message);
        }
      }
      this.getPMG(0, this.currentPage);
      this.getAdmin();
    },
    watch: {
      perPage: {
        handler() {
          this.currentPage = 1;
          this.getPMG(this.type, 1);
        },
        deep: true,
      },
      currentPage: {
        handler() {
          this.getPMG("1", this.currentPage);
        },
        deep: true,
      },
    },
    methods: {
      onThumbnailPicChange: async function (e) {
        if (e.target.files && e.target.files[0]) {
          let imageType = e.target.files[0].name.split(".");
          for (let i = 0; i < this.validImageTypes.length; i++) {
            if (
              imageType[imageType.length - 1].toLowerCase() ==
              this.validImageTypes[i]
            ) {
              this.thumbnailPicUrl = URL.createObjectURL(e.target.files[0]);
              this.thumbnailPicImage = e.target.files[0];
              this.thumbnailPicError = "";
              break;
            } else {
              this.thumbnailPicUrl = "";
              this.thumbnailPicImage = "";
              this.thumbnailPicError =
                "Image type must be either jpg, jpeg or png";
            }
          }
        }
      },
      onThumbnailFileChange: async function (e) {
        if (e.target.files && e.target.files[0]) {
          let imageType = e.target.files[0].name.split(".");
          if (imageType[imageType.length - 1].toLowerCase() == "mp4") {
            this.thumbnailurl = URL.createObjectURL(e.target.files[0]);
            this.thumbnailVideo = e.target.files[0];
            this.thumbnailError = "";
          } else {
            this.thumbnailurl = "";
            this.thumbnailVideo = "";
            this.thumbnailError = "Image type must be MP4";
          }
        }
      },
      getPMG(type, page) {
        if (type != null) {
          this.type = type;
        }
        try {
          axios({
            method: "get",
            url:
              this.url +
              `api/admin/allpmg/thumb?thumb_status=${this.type}&pageSize=${this.perPage}&page=${page}`,
            // headers: { ...data.getHeaders() },
          }).then((response) => {
            this.thumbnailData = response.data.results;
            this.totalRows = response.data.metadata.data;
          });
        } catch (e) {
          console.log("error", e.message);
        }
      },
      getAdmin() {
        if (this.contract) {
          try {
            axios({
              method: "get",
              url: this.url + "api/admin/",
              // headers: { ...data.getHeaders() },
            }).then((response) => {
              response.data.map((data) => {
                if (data.status === true) {
                  this.authorisedAddress.push(data);
                }
              });
              // this.authorisedAddress = response.data;
              // this.authorisedAddress.push({ address: this.ownerAddress });
            });
          } catch (e) {
            console.log("error", e.message);
          }
        }
      },
      getDate(date) {
        return moment(date).format("DD/MM/YYYY hh:mm:ss a");
      },
      storeData(id, address, cert) {
        this.updateID = id;
        this.updateAddress = address;
        this.updateCert = cert;
      },
      resetModal() {
        this.thumbnail = "";
        this.addThumbnailError = "";
        this.thumbnailurl = "";
        this.thumbnailVideo = "";
        this.nameState = null;
      },
      showAlert(type, title) {
        this.$swal
          .fire({
            position: "center",
            icon: type,
            title: title,
            showConfirmButton: true,
            backdrop: true,
            allowOutsideClick: false,
          })
          .then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
      },
      showLoading() {
        this.$swal.fire({
          position: "center",
          title: "Processing...",
          showConfirmButton: false,
          backdrop: true,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
        });
      },
      addThumbnail() {
        let success = false;
        // var add = "0x12d327c101C100Ef011E294e0c3D825bF16a13eB";
        if (this.thumbnailVideo != "" && this.updateID) {
          for (let i = 0; i < this.authorisedAddress.length; i++) {
            if (
              // add.toLowerCase() ==
              this.signer == this.authorisedAddress[i].address
            ) {
              success = true;
              this.showLoading();
              let data = new FormData();
              data.append(
                "thumbnail",
                this.thumbnailVideo,
                this.thumbnailVideo.name
              );

              data.append(
                "thumbnail_picture",
                this.thumbnailPicImage,
                this.thumbnailPicImage.name
              );

              try {
                axios({
                  method: "post",
                  url: this.url + "api/pmg/updateThumbnail/" + this.updateID,
                  data: data,
                  headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: "Basic YWRtaW46TExEUE54X1pxOTQ/QWEhUw==",
                  },
                }).then(() => {
                  this.showAlert("success", "Thumbnail Add Success.");
                });
              } catch (e) {
                this.showAlert("error", "ID not exist");
                console.log("error", e.message);
              }
            }
          }
          if (success == false) {
            this.addThumbnailError = "Not authorised for this action.";
          }
        } else {
          this.addThumbnailError = "Make sure Thumbnail is uploaded.";
        }
      },
    },
  };
</script>

<style>
  .status-header {
    color: white;
    text-align: start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
  }

  .function-bar > div > button {
    border: 1px solid #5454d0;
    background: linear-gradient(to bottom, #5454d0 100%, #2a25b5 100%);
    color: white;
    text-shadow: 0px 1px #000000;
    padding: 5px 10px;
    background-size: 200% 200%;
    border-radius: 30px !important;
  }

  .page-item > span,
  .page-item > button {
    background: rgba(211, 211, 211, 0.1) !important;
    color: grey;
  }

  .page-item > button:hover {
    color: white;
  }

  .page-item.active .page-link {
    border-color: white !important;
  }

  .search-section {
    align-self: center;
  }

  .search-button {
    margin-left: 5px;
  }

  .table {
    color: white !important;
    background-color: rgba(211, 211, 211, 0.1);
    margin: 0 auto;
    width: 90% !important;
    padding: 0px 10px !important;
  }

  .table > thead:first-child {
    background-color: #5454d0;
  }

  .function-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .display > button {
    margin: 0px 5px;
  }

  .thumbnail-status {
    padding: 10px;
  }

  .update {
    margin: 0 auto;
  }

  .nav-fill .nav-item .nav-link,
  .nav-justified .nav-item .nav-link,
  .nav-tabs .nav-link {
    border: 1px solid white !important;
    background: transparent;
    color: white;
  }

  .nav-tabs .nav-link:hover {
    color: white;
  }

  .nav-fill .nav-item .nav-link.active,
  .nav-justified .nav-item .nav-link.active,
  .nav-tabs .nav-link.active {
    background: linear-gradient(to bottom, #5454d0 100%, #2a25b5 100%);
    color: white !important;
  }
</style>
