<template>
  <div>
    <!-- <v-zxing /> -->
    <div v-if="signer" class="scanner">
      <qrcode-stream
        :camera="camera"
        @decode="onDecode"
        @init="onInit"
      ></qrcode-stream>
    </div>
    <div class="error">{{ this.scanError }}</div>
  </div>
</template>

<script>
  var axios = require("axios");
  import ABI from "../assets/ABI/AdminContract.json";
  const { ethers } = require("ethers");
  const provider = new ethers.providers.Web3Provider(window.ethereum);

  export default {
    name: "Scanner",
    data() {
      return {
        signer: "",
        contract: "",
        contractABI: ABI,
        contractAddress: process.env.VUE_APP_CONTRACT_ADDRESS,
        camera: "auto",
        showScanConfirmation: false,
        scanData: "",
        messageVNodeList: [],
        turtleList: [],
        claimedTurtleList: [],
        url: process.env.VUE_APP_API_URL,
        authorisedAddress: [],
      };
    },
    mounted: async function () {
      if (window.localStorage.getItem("address")) {
        try {
          const signer = provider.getSigner(); //user
          this.signer = await signer.getAddress();
          this.contract = new ethers.Contract(
            this.contractAddress,
            this.contractABI,
            signer
          );
          // this.ownerAddress = await this.contract.owner();
        } catch (e) {
          console.log(e.message);
        }
        this.getAdmin();
      }
    },
    // watch: {
    //   camera() {
    //     console.log(this.camera);
    //   },
    // },
    methods: {
      onDecode(result) {
        console.log(result);
        this.scanData = result;
        this.pause();
        this.getNFTdata();
      },

      async onInit(promise) {
        try {
          await promise;
        } catch (e) {
          console.error(e);
        } finally {
          this.showScanConfirmation = this.camera === "off";
        }
      },

      unpause() {
        this.camera = "auto";
      },

      pause() {
        this.camera = "off";
      },

      confirmMessage() {
        // this.$bvModal.msgBoxConfirm("Are you sure?");
        const h = this.$createElement;
        this.messageVNodeList = [];
        let claimedSerialList = [];
        let serialList = [];
        if (this.claimedTurtleList.length > 0) {
          for (let i = 0; i < this.claimedTurtleList.length; i++) {
            let data = h("div", { class: ["serial-box"] }, [
              this.claimedTurtleList[i].serial,
            ]);
            claimedSerialList.push(data);
          }
        }
        if (this.turtleList.length > 0) {
          for (let i = 0; i < this.turtleList.length; i++) {
            let data = h("div", { class: ["serial-box"] }, [
              this.turtleList[i].serial,
            ]);
            serialList.push(data);
          }
        }
        // console.log(serialList);
        // console.table(this.turtleList);
        const messageVNode = h("div", { class: ["foobar"] }, [
          h("div", { class: ["text-start"] }, [
            h("p", { class: ["text-title"] }, ["Claimed"]),
            // h("div", { class: ["text-data"] }, [
            //   h("p", { class: ["text-start"] }, [" : "]),
            // ]),
          ]),
          h("div", { class: ["serial-list"] }, [...claimedSerialList]),
          h("hr", { class: ["line"] }),
          h("div", { class: ["text-start"] }, [
            h("p", { class: ["text-title"] }, ["Unclaim"]),
            // h("div", { class: ["text-data"] }, [
            //   h("p", { class: ["text-start"] }, [" : "]),
            // ]),
          ]),
          h("div", { class: ["serial-list"] }, [...serialList]),
        ]);
        this.messageVNodeList.push(messageVNode);
        // }
        this.$bvModal
          .msgBoxConfirm(this.messageVNodeList, {
            title: "Are you Sure?",
            okTitle: "OK",
            cancelTitle: "Cancel",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value == true) {
              this.mint();
              this.unpause();
              //   window.location.reload();
            } else {
              this.unpause();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getNFTdata() {
        let success = false;
        this.turtleList = [];
        for (let i = 0; i < this.authorisedAddress.length; i++) {
          if (this.authorisedAddress[i].address == this.signer) {
            success = true;
            try {
              axios({
                method: "get",
                url:
                  this.url +
                  `api/admin/nfthbna/mbone?owner_address=` +
                  this.scanData,
                headers: {
                  "Content-Type": "application/json",
                },
              }).then((response) => {
                if (
                  response.data.available.length > 0 ||
                  response.data.results.length > 0
                ) {
                  this.claimedTurtleList = response.data.results;
                  this.turtleList = response.data.available;
                  this.confirmMessage();
                } else {
                  this.showAlert("error", "No NFT for this address", 2);
                  this.unpause();
                }
              });
            } catch (e) {
              this.showAlert("error", "Not authorised for this action", 2);
              this.unpause();
              console.log("error", e.message);
            }
          }
        }
        if (success == false) {
          this.showAlert("error", "Not authorised for this action.", 1);
        }
      },
      mint() {
        let success = false;
        for (let i = 0; i < this.authorisedAddress.length; i++) {
          if (this.authorisedAddress[i].address == this.signer) {
            success = true;
            this.showLoading();
            axios({
              method: "post",
              url: this.url + "api/admin/update/mbone_status",
              headers: {
                "Content-Type": "application/json",
                Authorization:
                  "Basic YWRtaW46TExEUE54tacozz0614X1pxOTQ/QWEhUw==",
              },
              data: {
                owner_address: this.scanData,
              },
            })
              .then((response) => {
                if (response.data.status == "Success") {
                  this.showAlert("success", "Claim Success", 1);
                } else {
                  this.showAlert("error", "Not authorised for this action", 2);
                  this.unpause();
                }
              })
              .catch(() => {
                this.showAlert(
                  "error",
                  "These NFT is claimed, please try another one.",
                  2
                );
                this.unpause();
                // this.showAlert("error", "Not authorised for this action", 2);
                // this.unpause();
              });
          }
        }
        if (success == false) {
          this.showAlert("error", "Not authorised for this action.", 1);
        }
      },
      showAlert(type, title, state, loadingTitle) {
        this.$swal
          .fire({
            position: "center",
            icon: type,
            title: title,
            showConfirmButton: true,
            backdrop: true,
            allowOutsideClick: false,
          })
          .then((result) => {
            if (result.isConfirmed && state == 0) {
              this.showLoading(loadingTitle);
            } else if (result.isConfirmed && state == 1) {
              window.location.reload();
            }
          });
      },
      showLoading(title) {
        this.$swal.fire({
          position: "center",
          title: title,
          showConfirmButton: false,
          backdrop: true,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
        });
      },
      getAdmin() {
        if (this.contract) {
          try {
            axios({
              method: "get",
              url: this.url + "api/admin/",
              // headers: { ...data.getHeaders() },
            }).then((response) => {
              response.data.map((data) => {
                if (data.status === true) {
                  this.authorisedAddress.push(data);
                }
              });
              // this.authorisedAddress = response.data;
              // this.authorisedAddress.push({ address: this.ownerAddress });
            });
          } catch (e) {
            console.log("error", e.message);
          }
        }
      },
    },
  };
</script>

<style>
  .scanner {
    width: 50%;
    height: 50%;
    margin: 0 auto;
  }

  .serial-list {
    display: flex;
    flex-wrap: wrap;
  }

  .serial-box {
    width: 100px;
  }

  .text-title {
    width: 250px;
  }

  @media only screen and (max-width: 1000px) {
    .scanner {
      width: 100%;
      height: 100%;
    }

    .modal-dialog {
      max-width: 100% !important;
    }

    .modal-content {
      height: 600px;
    }
  }
</style>
