<template>
  <div>
    <div>
      <div class="status-header">
        <h1>Country</h1>
      </div>
      <div class="country-section">
        <div>
          <input v-model="countryInput" type="text" />
          <div class="addbutton button" @click="addCountry()">&plus;</div>
          <div style="color: red">
            {{ this.addCountryError && this.addCountryError }}
          </div>
        </div>
        <div>
          <select>
            <option
              v-for="country in countryList"
              :value="country.name"
              :key="country.id"
            >
              {{ country.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div>
      <div class="status-header dealer-header">
        <h1>
          Dealer
          <div class="addbutton button" v-b-modal.modal-prevent-closing>
            &plus;
          </div>
        </h1>
      </div>
      <div>
        <b-table
          :items="DealerData"
          :per-page="perPage"
          :current-page="currentPage"
          :fields="fields"
          show-empty
          small
          responsive
          empty-filtered-text="No Data"
          class="Dealer-table"
        >
          <template #cell(id)="row">
            {{ row.item.id }}
          </template>
          <template #cell(image)="row">
            <div
              type="button"
              :style="[
                row.item.image ? { color: 'lightgreen' } : { color: 'red' },
              ]"
              @click="imgLink = row.item.image"
            >
              View
            </div>
          </template>
          <template #cell(name)="row">
            {{ row.item.name }}
          </template>
          <template #cell(email)="row">
            {{ row.item.email }}
          </template>
          <template #cell(company_name)="row">
            {{ row.item.company_name }}
          </template>
          <template #cell(company_addr)="row">
            <div v-if="!viewAddress" @click="viewAddress = !viewAddress">
              {{ row.item.company_addr.slice(0, 15) }}...
            </div>
            <div v-else @click="viewAddress = !viewAddress">
              <div>{{ row.item.company_addr }}</div>
            </div>
          </template>
          <template #cell(country)="row">
            {{ row.item.country }}
          </template>
          <template #cell(phone_number)="row">
            {{ row.item.phone_number }}
          </template>
          <template #cell(office_number)="row">
            {{ row.item.office_number }}
          </template>
          <template #cell(action)="row">
            <div class="action-section">
              <button
                class="b-button Dealerbutton button"
                @click="confirmMessage(row.item)"
              >
                Delete
              </button>
              <button
                class="b-button Dealerbutton button"
                @click="storeData(row.item)"
                v-b-modal.modal-prevent-closing2
              >
                Edit
              </button>
            </div>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalrows"
          :per-page="perPage"
          aria-controls="Dealer-table"
          align="center"
        ></b-pagination>
      </div>
    </div>

    <div class="imageViewer" id="imageViewer">
      <div class="close-btn" @click="closeImage()">&#10006;</div>
      <img :src="imgLink" />
    </div>

    <!-- Create Modal -->
    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      @show="resetModal"
      @hidden="resetModal"
      @ok.prevent="addDealer()"
      title="Create Dealer"
      centered
      scrollable
    >
      <form ref="form" @submit.stop.prevent="addDealer">
        <b-form-group
          label="Name"
          label-for="name"
          :state="nameState"
          invalid-feedback="Name is required."
        >
          <b-form-input
            id="name"
            v-model="name"
            :state="nameState"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Image"
          label-for="image"
          :state="nameState"
          invalid-feedback="Image is required."
        >
          <div>
            <img
              v-if="imagePreviewUrl"
              :src="imagePreviewUrl"
              width="300px"
              height="300px"
            />
            <button class="uploadImage button">
              <input type="file" @change="onImageChange" />
              {{ $t("Upload Image") }}
            </button>
            <div class="error d-none">{{ imageError && imageError }}</div>
          </div>
          <!-- <input type="file" @change="onImageChange" /> -->
          <!-- <b-form-file v-model="image" class="mt-3" plain></b-form-file> -->
        </b-form-group>

        <b-form-group label="Email" label-for="email">
          <b-form-input id="email" v-model="email" type="email"></b-form-input>
        </b-form-group>
        <b-form-group
          label="Company Name"
          label-for="company_name"
          :state="nameState"
          invalid-feedback="Company Name is required."
        >
          <b-form-input
            id="company_name"
            v-model="company_name"
            :state="nameState"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Company Address"
          label-for="company_addr"
          :state="nameState"
          invalid-feedback="Company Address is required."
        >
          <b-form-input
            id="company_Addr"
            v-model="company_addr"
            :state="nameState"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Country"
          label-for="country"
          :state="nameState"
          invalid-feedback="Country is required."
        >
          <b-form-select
            class="form-control"
            id="country"
            v-model="country"
            :state="nameState"
            required
          >
            <template #first>
              <b-form-select-option :value="null" disabled
                >-- Please select an option --</b-form-select-option
              >
            </template>
            <b-form-select-option
              v-for="country in countryList"
              :key="country.id"
              :value="country.name"
              >{{ country.name }}</b-form-select-option
            >
          </b-form-select>
        </b-form-group>
        <b-form-group label="Phone Number" label-for="phone_number">
          <b-form-input id="phone_number" v-model="phone_number"></b-form-input>
        </b-form-group>
        <b-form-group label="Office Number" label-for="office_number">
          <b-form-input
            id="office_number"
            v-model="office_number"
            :state="nameState"
          ></b-form-input>
        </b-form-group>
        <div class="error">
          {{ this.addDealerError && this.addDealerError }}
        </div>
      </form>
    </b-modal>

    <!-- Edit Modal -->
    <b-modal
      id="modal-prevent-closing2"
      ref="modal"
      @hidden="resetModal"
      @ok.prevent="editDealer()"
      title="Edit Dealer"
      centered
      scrollable
    >
      <form ref="form" @submit.stop.prevent="editDealer">
        <p>ID : {{ this.id }}</p>
        <b-form-group
          label="image"
          label-for="image"
          :state="nameState"
          invalid-feedback="Image is required."
        >
          <div>
            <img
              v-if="imagePreviewUrl"
              :src="imagePreviewUrl"
              width="300px"
              height="300px"
            />
            <button type="button" class="uploadImage button">
              <input type="file" @change="onImageChange" />
              {{ $t("Upload Image") }}
            </button>
            <div class="error d-none">{{ imageError && imageError }}</div>
          </div>
          <!-- <input type="file" @change="onImageChange" /> -->
          <!-- <b-form-file v-model="image" class="mt-3" plain></b-form-file> -->
        </b-form-group>
        <b-form-group
          label="Name"
          label-for="name"
          :state="nameState"
          invalid-feedback="Name is required."
        >
          <b-form-input
            id="name"
            v-model="name"
            :state="nameState"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Email" label-for="email">
          <b-form-input id="email" v-model="email" type="email"></b-form-input>
        </b-form-group>
        <b-form-group
          label="Company Name"
          label-for="company_name"
          :state="nameState"
          invalid-feedback="Company Name is required."
        >
          <b-form-input
            id="company_name"
            v-model="company_name"
            :state="nameState"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Company Address"
          label-for="company_addr"
          :state="nameState"
          invalid-feedback="Company Address is required."
        >
          <b-form-input
            id="company_Addr"
            v-model="company_addr"
            :state="nameState"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Country"
          label-for="country"
          :state="nameState"
          invalid-feedback="Country is required."
        >
          <b-form-select
            class="form-control"
            id="country"
            v-model="country"
            :state="nameState"
            required
          >
            <template #first>
              <b-form-select-option :value="null" disabled
                >-- Please select an option --</b-form-select-option
              >
            </template>
            <b-form-select-option
              v-for="country in countryList"
              :key="country.id"
              :value="country.name"
              >{{ country.name }}</b-form-select-option
            >
          </b-form-select>
        </b-form-group>
        <b-form-group label="Phone Number" label-for="phone_number">
          <b-form-input id="phone_number" v-model="phone_number"></b-form-input>
        </b-form-group>
        <b-form-group label="Office Number" label-for="office_number">
          <b-form-input
            id="office_number"
            v-model="office_number"
          ></b-form-input>
        </b-form-group>
        <div class="error">
          {{ this.editDealerError && this.editDealerError }}
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
  import ABI from "../assets/ABI/AdminContract.json";
  var axios = require("axios");
  const { ethers } = require("ethers");
  const provider = new ethers.providers.Web3Provider(window.ethereum);

  export default {
    name: "Dealer",
    data() {
      return {
        fields: [
          { key: "id", label: "ID" },
          { key: "name", label: "Name" },
          { key: "image", label: "Image" },
          { key: "email", label: "Email" },
          {
            key: "company_name",
            label: "Company Name",
          },
          {
            key: "company_addr",
            label: "Company Address",
          },
          { key: "country", label: "Country" },
          { key: "phone_number", label: "Phone Number" },
          { key: "office_number", label: "Office Number" },
          { key: "phone_number", label: "Phone Number" },
          { key: "action", label: "Action" },
        ],
        currentPage: 1,
        perPage: 100,
        totalrows: null,
        url: process.env.VUE_APP_API_URL,
        DealerData: "",
        contract: "",
        contractABI: ABI,
        contractAddress: process.env.VUE_APP_CONTRACT_ADDRESS,
        signer: "",
        authorisedAddress: [],
        viewAddress: false,
        imgLink: "",
        storedData: "",
        id: "",
        name: "",
        email: "",
        image: "",
        imagePreviewUrl: "",
        company_name: "",
        company_addr: "",
        country: null,
        phone_number: "",
        office_number: "",
        addDealerError: "",
        editDealerError: "",
        nameState: null,
        countryList: [],
        countryInput: "",
        addCountryError: "",
        editCountryInput: "",
        editCountryError: "",
        validImageTypes: ["jpg", "jpeg", "png"],
      };
    },
    mounted: async function () {
      if (window.localStorage.getItem("address")) {
        try {
          const signer = provider.getSigner(); //user
          this.signer = await signer.getAddress();
          this.contract = new ethers.Contract(
            this.contractAddress,
            this.contractABI,
            signer
          );
          // this.ownerAddress = await this.contract.owner();
        } catch (e) {
          console.log(e.message);
        }
      }
      this.getAdmin();
      this.getDealer(this.currentPage, "");
      this.getCountry();
    },
    watch: {
      imgLink: {
        handler() {
          if (this.imgLink) {
            document.getElementById("imageViewer").style.display = "block";
          }
        },
      },
      currentPage: {
        handler() {
          this.getDealer(this.currentPage, this.selectedCountry);
        },
      },
    },
    methods: {
      onImageChange: async function (e) {
        if (e.target.files && e.target.files[0]) {
          let imageType = e.target.files[0].name.split(".");
          for (let i = 0; i < this.validImageTypes.length; i++) {
            if (
              imageType[imageType.length - 1].toLowerCase() ==
              this.validImageTypes[i]
            ) {
              this.imagePreviewUrl = URL.createObjectURL(e.target.files[0]);
              this.image = e.target.files[0];
              break;
            } else {
              this.imagePreviewUrl = "";
              this.image = "";
              this.imageError = "Image type must be either jpg, jpeg or png";
            }
          }
        }
      },
      reloadPage() {
        window.location.reload();
      },
      closeImage() {
        this.imgLink = "";
        document.getElementById("imageViewer").style.display = "none";
      },
      storeData(data) {
        this.storedData = data;
        this.id = data.id;
        this.email = data.email;
        this.name = data.name;
        this.company_name = data.company_name;
        this.company_addr = data.company_addr;
        this.country = data.country ?? null;
        this.office_number = data.office_number;
        this.phone_number = data.phone_number;
        this.imagePreviewUrl = data.image;
        this.selectedId = data.id;
      },
      checkFormValidity() {
        // console.log(
        //   this.id,
        //   this.email,
        //   this.name,
        //   this.company_name,
        //   this.company_addr,
        //   this.country,
        //   this.phone_number,
        //   this.office_number,
        //   this.nameState
        // );
        const valid = this.$refs.form.checkValidity();
        console.log(valid);
        this.nameState = valid;
        return valid;
      },
      resetModal() {
        this.storedData = "";
        this.id = "";
        this.name = "";
        this.email = "";
        this.image = "";
        this.company_name = "";
        this.company_addr = "";
        this.country = null;
        this.phone_number = "";
        this.office_number = "";
        this.addDealerError = "";
        this.editDealerError = "";
        this.imagePreviewUrl = "";
        this.nameState = null;
      },
      getAdmin() {
        if (this.contract) {
          try {
            axios({
              method: "get",
              url: this.url + "api/admin/",
              // headers: { ...data.getHeaders() },
            }).then((response) => {
              response.data.map((data) => {
                if (data.status === true) {
                  this.authorisedAddress.push(data);
                }
              });
              // this.authorisedAddress = response.data;
              // this.authorisedAddress.push({ address: this.ownerAddress });
            });
          } catch (e) {
            console.log("error", e.message);
          }
        }
      },
      getDealer(page, country) {
        try {
          axios({
            method: "get",
            url:
              this.url +
              `api/dealer?pageSize=${this.perPage}&page=${page}&country=${country}`,
            // headers: { ...data.getHeaders() },
          }).then((response) => {
            this.DealerData = response.data.results;
            this.perPage = response.data.metadata.total;
          });
        } catch (e) {
          console.log("error", e.message);
        }
      },
      getCountry() {
        try {
          axios({
            method: "get",
            url: this.url + `api/dealer/country`,
            // headers: { ...data.getHeaders() },
          }).then((response) => {
            this.countryList = response.data;
          });
        } catch (e) {
          console.log("error", e.message);
        }
      },
      addCountry() {
        if (this.countryInput) {
          let success = false;
          for (let i = 0; i < this.authorisedAddress.length; i++) {
            if (this.authorisedAddress[i].address == this.signer) {
              success = true;
              axios({
                method: "post",
                url: this.url + "api/admin/country/create",
                headers: {
                  Authorization: "Basic YWRtaW46TExEUE54X1pxOTQ/QWEhUw==",
                },
                data: {
                  country_name: this.countryInput,
                },
              })
                .then(() => {
                  this.showAlert("success", "Success Added", 0);
                })
                .catch(() => {
                  this.showAlert("error", "Add Failed.", 1);
                });
            }
          }
          if (success == false) {
            this.showAlert("error", "Not authorised for this action.", 1);
          }
        } else {
          this.addCountryError = "Input cannot be empty.";
        }
      },
      addDealer() {
        if (!this.checkFormValidity()) {
          return;
        }
        let formData = new FormData();
        let success = false;
        for (let i = 0; i < this.authorisedAddress.length; i++) {
          if (this.authorisedAddress[i].address == this.signer) {
            success = true;
            formData.append("name", this.name);
            formData.append("email", this.email);
            formData.append("company_name", this.company_name);
            formData.append("company_address", this.company_addr);
            formData.append("country", this.country);
            formData.append("contact_no", this.phone_number);
            formData.append("office_tel_no", this.office_number);
            axios({
              method: "post",
              url: this.url + "api/admin/dealer/create",
              headers: {
                Authorization: "Basic YWRtaW46TExEUE54X1pxOTQ/QWEhUw==",
                "Content-Type": "multipart/form-data",
              },
              data: formData,
            })
              .then(() => {
                this.showAlert("success", "Success Added", 0);
              })
              .catch(() => {
                this.showAlert("error", "Add Failed.", 1);
              });
          }
        }
        if (success == false) {
          this.showAlert("error", "Not authorised for this action.", 1);
        }
      },
      editDealer() {
        if (!this.checkFormValidity()) {
          return;
        }
        let success = false;
        for (let i = 0; i < this.authorisedAddress.length; i++) {
          if (this.authorisedAddress[i].address == this.signer) {
            success = true;

            axios({
              method: "post",
              url: this.url + "api/admin/dealer/edit",
              headers: {
                Authorization: "Basic YWRtaW46TExEUE54X1pxOTQ/QWEhUw==",
              },
              data: {
                name: this.name,
                email: this.email,
                company_name: this.company_name,
                company_address: this.company_addr,
                country: this.country,
                contact_no: this.phone_number,
                office_tel_no: this.office_number,
                id: this.selectedId,
              },
            })
              .then(async () => {
                if (typeof this.image == "object") {
                  // alert('hi update');
                  let imgFormData = new FormData();
                  imgFormData.append("image", this.image);
                  imgFormData.append("id", this.selectedId);

                  await axios.post(
                    `${this.url}api/admin/dealer/edit_image`,
                    imgFormData,
                    {
                      headers: {
                        Authorization: "Basic YWRtaW46TExEUE54X1pxOTQ/QWEhUw==",
                        "Content-Type": "multipart/form-data",
                      },
                    }
                  );
                  this.showAlert("success", "Update Successfully", 0);
                } else {
                  this.showAlert("success", "Update Successfully", 0);
                }
              })
              .catch(() => {
                this.showAlert("error", "Edit Failed.", 1);
              });
          }
        }
        if (success == false) {
          this.showAlert("error", "Not authorised for this action.", 1);
        }
      },
      deleteDealer(id) {
        let success = false;
        for (let i = 0; i < this.authorisedAddress.length; i++) {
          if (this.authorisedAddress[i].address == this.signer) {
            success = true;
            try {
              axios({
                method: "delete",
                url: this.url + "api/admin/dealer/remove",
                headers: {
                  Authorization: "Basic YWRtaW46TExEUE54X1pxOTQ/QWEhUw==",
                },
                data: {
                  id: id,
                },
              }).then(() => {
                this.showAlert("success", "Success Delete", 0);
              });
            } catch (e) {
              this.showAlert("error", "Delete Failed.", 1);
              console.log("error", e.message);
            }
          }
        }
        if (success == false) {
          this.showAlert("error", "Not authorised for this action.", 1);
        }
      },
      showLoading() {
        this.$swal.fire({
          position: "center",
          title: "Processing...",
          showConfirmButton: false,
          backdrop: true,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
        });
      },
      showAlert(type, title, state) {
        this.$swal
          .fire({
            position: "center",
            icon: type,
            title: title,
            showConfirmButton: true,
            backdrop: true,
            allowOutsideClick: false,
          })
          .then((result) => {
            if (result.isConfirmed && state == 0) {
              this.reloadPage();
            }
          });
      },
      confirmMessage(data) {
        const h = this.$createElement;
        this.messageVNodeList = h("div", { class: ["foobar"] }, [
          h("div", { class: ["text-start"] }, [
            h("p", { class: ["text-title"] }, ["ID"]),
            h("div", { class: ["text-data"] }, [
              h("p", { class: ["text-start"] }, [" : ", data.id]),
            ]),
          ]),
          h("div", { class: ["text-start"] }, [
            h("p", { class: ["text-title"] }, ["Name"]),
            h("div", { class: ["text-data"] }, [
              h("p", { class: ["text-start"] }, [" : ", data.name]),
            ]),
          ]),
          h("div", { class: ["text-start"] }, [
            h("p", { class: ["text-title"] }, ["Email"]),
            h("div", { class: ["text-data"] }, [
              h("p", { class: ["text-start"] }, [" : ", data.email]),
            ]),
          ]),
          h("div", { class: ["text-start"] }, [
            h("p", { class: ["text-title"] }, ["Company Name"]),
            h("div", { class: ["text-data"] }, [
              h("p", { class: ["text-start"] }, [" : ", data.company_name]),
            ]),
          ]),
          h("div", { class: ["text-start"] }, [
            h("p", { class: ["text-title"] }, ["Company Address"]),
            h("div", { class: ["text-data"] }, [
              h("p", { class: ["text-start"] }, [" : ", data.company_addr]),
            ]),
          ]),
          h("div", { class: ["text-start"] }, [
            h("p", { class: ["text-title"] }, ["Country"]),
            h("div", { class: ["text-data"] }, [
              h("p", { class: ["text-start"] }, [" : ", data.country]),
            ]),
          ]),
          h("div", { class: ["text-start"] }, [
            h("p", { class: ["text-title"] }, ["Phone Number"]),
            h("div", { class: ["text-data"] }, [
              h("p", { class: ["text-start"] }, [" : ", data.phone_number]),
            ]),
          ]),
          h("div", { class: ["text-start"] }, [
            h("p", { class: ["text-title"] }, ["Office Number"]),
            h("div", { class: ["text-data"] }, [
              h("p", { class: ["text-start"] }, [" : ", data.office_number]),
            ]),
          ]),
        ]);

        this.$bvModal
          .msgBoxConfirm(this.messageVNodeList, {
            title: "Are you Sure?",
            okTitle: "OK",
            cancelTitle: "Cancel",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value == true) {
              this.deleteDealer(data.id);
              //   window.location.reload();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
    },
  };
</script>

<style>
  .dealer-header > h1 {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
    align-items: center;
  }

  .addbutton {
    width: 50px;
    height: 50px;
    font-size: 1em;
    padding: 0px;
    text-align: center;
    margin-left: 30px;
  }

  .action-section {
    display: flex;
    flex-direction: row;
  }

  .Dealerbutton {
    padding: 10px 15px;
    margin: 0px 5px;
  }

  .modal-body {
    height: 100%;
  }

  .close-btn {
    color: white;
    font-size: 2em;
    width: 10%;
    padding: 30px;
    margin-left: auto;
  }

  .imageViewer {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgb(0, 0, 0, 0.8);
  }

  .imageViewer > img {
    width: 50%;
    margin: auto;
    /* transform: translateY(30%); */
  }

  @media only screen and (max-width: 1000px) {
    .close-btn {
      width: 20%;
    }

    .imageViewer > img {
      width: 90%;
      transform: translateY(30%);
    }

    .addbutton {
      font-size: 1.5em;
    }
  }

  .country-section {
    margin: 0 auto;
    width: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .country-section > div {
    display: flex;
    flex-direction: row;

    align-items: center;
    align-self: center;
  }

  .country-section select {
    font-size: 1em;
    padding: 5px 10px;
  }

  .country-section > div:first-child > .addbutton {
    font-size: 2em;
    margin: 0px 10px;
  }

  .page-item.active .page-link {
    z-index: 0 !important;
  }
</style>
