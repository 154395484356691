<template>
  <div>
    <div>
      <b-tabs content-class="mt-3" fill>
        <b-tab title="With Owner" active>
          <b-tabs content-class="mt-3" @click="getWithUserPMG('pending', 1)">
            <b-tab
              title="Pending"
              active
              @click="getWithUserPMG('pending', pendingCurrentPage)"
            >
              <div class="status-header">
                <h1>Pending Request</h1>
                <div class="function-bar">
                  <div class="display">
                    <span>{{ $t("Show Per Page") }} : </span>
                    <button @click="perPage = 5">5</button>
                    <button @click="perPage = 20">20</button>
                    <button @click="perPage = 50">50</button>
                    <button @click="perPage = 100">100</button>
                  </div>
                  <div class="search-section">
                    <input type="text" v-model="searchWord" />
                    <button
                      class="search-button"
                      @click="searchWithUser('pending')"
                    >
                      Search
                    </button>
                    <button class="search-button" @click="reloadPage">
                      Reset
                    </button>
                  </div>
                </div>
              </div>
              <b-table
                id="pendingPMG-table"
                :items="pendingPMG"
                :per-page="0"
                :current-page="pendingCurrentPage"
                :fields="fields"
                show-empty
                small
                responsive
                empty-filtered-text="No Data"
                class="PMG-table"
              >
                <template #cell(Index)="row">
                  {{ row.index + 1 }}
                </template>
                <template #cell(current_owner)="row">
                  <div class="address-field">
                    <div>
                      {{ row.item.current_owner }}
                      <!-- {{
                        row.item.current_owner.substr(0, 6) +
                        "...." +
                        row.item.current_owner.substr(-4, 4)
                      }} -->
                    </div>
                  </div>
                </template>
                <!-- <template #cell(wallet_address)="row">
                  <div class="address-field" v-b-toggle.collapse-1>
                    <div>
                      {{ row.item.wallet_address }}
                    </div>
                  </div>
                </template> -->
                <template #empty="scope">
                  <p class="text-center">{{ (scope.emptyText = "No Data") }}</p>
                </template>
                <template #cell(pmg_cert)="row">
                  <div class="pmg-cert" v-b-toggle.collapse-1>
                    <div>{{ row.item.pmg_cert }}</div>
                  </div>
                </template>
                <template #cell(created_at)="row">
                  <div v-b-toggle.collapse-1>
                    <div>{{ getDate(row.item.claim_created_at) }}</div>
                  </div>
                </template>
                <template #cell(info)="row">
                  <div class="user-info" v-b-toggle.collapse-1>
                    <div>{{ row.item.real_name }}</div>
                    <b-collapse id="collapse-1">
                      <div>{{ row.item.phone_number }}</div>
                      <div>{{ row.item.shipping_address }}</div>
                    </b-collapse>
                  </div>
                </template>
                <!-- <template #cell(status)="row">
                  <div>
                    {{ row.item.status }}
                  </div>
                </template> -->
                <template #cell(action)="row">
                  <div>
                    <div class="button-section">
                      <button
                        class="b-button button update"
                        @click="
                          updatePMGStatus(
                            row.item.claim_id,
                            row.item.token_id,
                            row.item.contract_address,
                            'sent'
                          )
                        "
                      >
                        {{ $t("Sent") }}
                      </button>
                      <button
                        class="b-button button update"
                        @click="
                          updatePMGStatus(
                            row.item.claim_id,
                            row.item.token_id,
                            row.item.contract_address,
                            'returned'
                          )
                        "
                      >
                        {{ $t("Cancel Request") }}
                      </button>
                    </div>
                  </div>
                </template>
              </b-table>
              <b-pagination
                v-model="pendingCurrentPage"
                :total-rows="pendingTotalRows"
                :per-page="perPage"
                aria-controls="pendingPMG-table"
                align="center"
              ></b-pagination>
            </b-tab>
            <b-tab
              title="Sent"
              @click="getWithUserPMG('sent', sentCurrentPage)"
            >
              <div class="status-header">
                <h1>Sent</h1>
                <div class="function-bar">
                  <div class="display">
                    <span>{{ $t("Show Per Page") }} : </span>
                    <button @click="perPage = 5">5</button>
                    <button @click="perPage = 20">20</button>
                    <button @click="perPage = 50">50</button>
                    <button @click="perPage = 100">100</button>
                  </div>
                  <div class="search-section">
                    <input type="text" v-model="searchWord" />
                    <button
                      class="search-button"
                      @click="searchWithUser('sent')"
                    >
                      Search
                    </button>
                    <button class="search-button" @click="reloadPage">
                      Reset
                    </button>
                  </div>
                </div>
              </div>
              <b-table
                :items="sentPMG"
                :per-page="0"
                :current-page="sentCurrentPage"
                :fields="fields"
                show-empty
                small
                responsive
                empty-filtered-text="No Data"
                class="PMG-table"
                id="sentPMG-table"
              >
                <template #cell(Index)="row">
                  {{ row.index + 1 }}
                </template>
                <template #cell(current_owner)="row">
                  <div>
                    <div class="address-field">
                      {{ row.item.current_owner }}
                    </div>
                  </div>
                </template>
                <!-- <template #cell(wallet_address)="row">
                  <div>
                    <div class="address-field">
                      {{ row.item.wallet_address }}
                    </div>
                  </div>
                </template> -->
                <template #empty="scope">
                  <p class="text-center">{{ (scope.emptyText = "No Data") }}</p>
                </template>
                <template #cell(pmg_cert)="row">
                  <div>
                    <div>{{ row.item.pmg_cert }}</div>
                  </div>
                </template>
                <template #cell(created_at)="row">
                  <div>
                    <div>{{ getDate(row.item.claim_created_at) }}</div>
                  </div>
                </template>
                <template #cell(info)="row">
                  <div class="user-info" v-b-toggle.collapse-1>
                    <div>{{ row.item.real_name }}</div>
                    <b-collapse id="collapse-1">
                      <div>{{ row.item.phone_number }}</div>
                      <div>{{ row.item.shipping_address }}</div>
                    </b-collapse>
                  </div>
                </template>
                <template #cell(action)="row">
                  <div>
                    <div class="button-section return">
                      <button
                        class="b-button button update"
                        @click="
                          updatePMGStatus(
                            row.item.claim_id,
                            row.item.token_id,
                            row.item.contract_address,
                            'returned'
                          )
                        "
                      >
                        {{ $t("Note Received") }}
                      </button>
                    </div>
                  </div>
                </template>
              </b-table>
              <b-pagination
                v-model="sentCurrentPage"
                :total-rows="sentTotalRows"
                :per-page="perPage"
                aria-controls="sentPMG-table"
                align="center"
              ></b-pagination>
            </b-tab>
            <b-tab
              title="Physical Note with Company"
              @click="getWithUserPMG('returned', returnedCurrentPage)"
            >
              <div class="status-header">
                <h1>Physical Note with Company</h1>
                <div class="function-bar">
                  <div class="display">
                    <span>{{ $t("Show Per Page") }} : </span>
                    <button @click="perPage = 5">5</button>
                    <button @click="perPage = 20">20</button>
                    <button @click="perPage = 50">50</button>
                    <button @click="perPage = 100">100</button>
                  </div>
                  <div class="search-section">
                    <input type="text" v-model="searchWord" />
                    <button
                      class="search-button"
                      @click="searchWithUser('returned')"
                    >
                      Search
                    </button>
                    <button class="search-button" @click="reloadPage">
                      Reset
                    </button>
                  </div>
                </div>
              </div>
              <b-table
                :items="returnedPMG"
                :per-page="0"
                :current-page="returnedCurrentPage"
                :fields="returnfields"
                show-empty
                small
                responsive
                empty-filtered-text="No Data"
                class="PMG-table"
                id="withNumisartPMG-table"
              >
                <template #cell(Index)="row">
                  {{ row.index + 1 }}
                </template>
                <template #cell(current_owner)="row">
                  <div>
                    <div class="address-field">
                      {{ row.item.current_owner }}
                    </div>
                  </div>
                </template>
                <!-- <template #cell(pioneer_address)="row">
                  <div>
                    <div class="address-field">
                      {{ row.item.pioneer_address }}
                    </div>
                  </div>
                </template> -->
                <template #empty="scope">
                  <p class="text-center">{{ (scope.emptyText = "No Data") }}</p>
                </template>
                <template #cell(pmg_cert)="row">
                  <div>
                    <div>{{ row.item.pmg_cert }}</div>
                  </div>
                </template>
                <template #cell(created_at)="row">
                  <div>
                    <div>{{ getDate(row.item.created_at) }}</div>
                  </div>
                </template>
                <template #cell(info)="row">
                  <div class="user-info" v-b-toggle.collapse-1>
                    <div>{{ row.item.real_name }}</div>
                    <b-collapse id="collapse-1">
                      <div>{{ row.item.phone_number }}</div>
                      <div>{{ row.item.shipping_address }}</div>
                    </b-collapse>
                  </div>
                </template>
              </b-table>
              <b-pagination
                v-model="returnedCurrentPage"
                :total-rows="returnedTotalRows"
                :per-page="perPage"
                aria-controls="returnedPMG-table"
                align="center"
              ></b-pagination>
            </b-tab>
          </b-tabs>
        </b-tab>
        <b-tab title="With Company" @click="getWithNumisartPMG(1)">
          <div class="status-header">
            <h1>With Company</h1>
            <div class="function-bar">
              <div class="display">
                <span>{{ $t("Show Per Page") }} : </span>
                <button @click="perPage = 5">5</button>
                <button @click="perPage = 20">20</button>
                <button @click="perPage = 50">50</button>
                <button @click="perPage = 100">100</button>
              </div>
              <div class="search-section">
                <input type="text" v-model="searchWord" />
                <button class="search-button" @click="searchWithNumisart">
                  Search
                </button>
                <button class="search-button" @click="reloadPage">Reset</button>
              </div>
            </div>
          </div>
          <b-table
            :items="withNumisartPMG"
            :per-page="0"
            :current-page="withNumisartCurrentPage"
            :fields="withfields"
            show-empty
            small
            responsive
            empty-filtered-text="No Data"
            class="PMG-table"
            id="withNumisartPMG-table"
          >
            <template #cell(Index)="row">
              {{ row.index + 1 }}
            </template>
            <template #cell(current_owner)="row">
              <div>
                <div class="address-field">
                  {{ row.item.current_owner }}
                </div>
              </div>
            </template>
            <!-- <template #cell(pioneer_address)="row">
                  <div>
                    <div class="address-field">
                      {{ row.item.pioneer_address }}
                    </div>
                  </div>
                </template> -->
            <template #empty="scope">
              <p class="text-center">{{ (scope.emptyText = "No Data") }}</p>
            </template>
            <template #cell(pmg_cert)="row">
              <div>
                <div>{{ row.item.pmg_cert }}</div>
              </div>
            </template>
            <template #cell(created_at)="row">
              <div>
                <div>{{ getDate(row.item.created_at) }}</div>
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="withNumisartCurrentPage"
            :total-rows="withNumisartTotalRows"
            :per-page="perPage"
            aria-controls="withNumisartPMG-table"
            align="center"
          ></b-pagination>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
  // import Vue from "vue";
  // import VueSweetalert2 from "vue-sweetalert2";
  // import "sweetalert2/dist/sweetalert2.min.css";

  // Vue.use(VueSweetalert2);
  var axios = require("axios");
  var moment = require("moment");
  import ABI from "../assets/ABI/AdminContract.json";
  import marketABI from "../assets/ABI/MarketplaceContract.json";
  const { ethers } = require("ethers");
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  // import { BIconXSquare, BIconCheckSquare } from "bootstrap-vue";
  import "bootstrap-vue/dist/bootstrap-vue-icons.min.css";

  export default {
    name: "NFTStatus",
    data() {
      return {
        loading: false,
        signer: "",
        perPage: 20,
        bankNoteRemark: "pending",
        pendingPMG: [],
        returnedPMG: [],
        sentPMG: [],
        withNumisartPMG: [],
        fields: [
          "Index",
          { key: "current_owner", label: "Current Owner" },
          // { key: "wallet_address", label: "Wallet Address" },
          { key: "pmg_cert", label: "PMG Cert" },
          { key: "created_at", label: "Created At", formatter: this.getDate },
          { key: "info", label: "Info" },
          // {
          //   key: "status",
          //   label: "Status",
          // },
          {
            key: "action",
            label: "Action",
          },
        ],
        returnfields: [
          "Index",
          { key: "current_owner", label: "Current Owner" },
          // { key: "wallet_address", label: "Wallet Address" },
          { key: "pmg_cert", label: "PMG Cert" },
          { key: "created_at", label: "Created At", formatter: this.getDate },
          { key: "info", label: "Info" },
        ],
        withfields: [
          "Index",
          { key: "current_owner", label: "Current Owner" },
          // { key: "pioneer_address", label: "Pioneer Address" },
          { key: "pmg_cert", label: "PMG Cert" },
          { key: "created_at", label: "Created At", formatter: this.getDate },
        ],
        searchWord: "",
        contractABI: ABI,
        marketABI: marketABI,
        pendingCurrentPage: 1,
        returnedCurrentPage: 1,
        sentCurrentPage: 1,
        withNumisartCurrentPage: 1,
        pendingTotalRows: null,
        sentTotalRows: null,
        returnedTotalRows: null,
        withNumisartTotalRows: null,
        updateID: "",
        authorisedAddress: [],
        totalRows: "",
        contractAddress: process.env.VUE_APP_CONTRACT_ADDRESS,
        marketAddress: process.env.VUE_APP_MARKET_CONTRACT_ADDRESS,
        currentPage: 1,
        state: "",
        mode: "",
        type: "",
        ownerAddress: "",
        url: process.env.VUE_APP_API_URL,
      };
    },
    components: {},
    mounted: async function () {
      if (window.localStorage.getItem("address")) {
        try {
          const signer = provider.getSigner(); //user
          this.signer = await signer.getAddress();
          this.contract = new ethers.Contract(
            this.contractAddress,
            this.contractABI,
            signer
          );
          // this.ownerAddress = await this.contract.owner();
        } catch (e) {
          console.log(e.message);
        }
      }
      this.getAdmin();
      this.getWithUserPMG("pending", this.pendingCurrentPage);
    },
    watch: {
      perPage: {
        handler() {
          if (this.type == "search") {
            if (this.status == "address") {
              switch (this.mode) {
                case "pending":
                  this.getUserPMGByAddress(this.mode, 1);
                  break;
                case "returned":
                  this.getUserPMGByAddress(this.mode, 1);
                  break;
                case "sent":
                  this.getUserPMGByAddress(this.mode, 1);
                  break;
                default:
                  this.getNumisPMGByAddress(1);
                  break;
              }
            } else if (this.status == "cert") {
              switch (this.mode) {
                case "pending":
                  this.getUserPMGByCert(this.mode, 1);
                  break;
                case "returned":
                  this.getUserPMGByCert(this.mode, 1);
                  break;
                case "sent":
                  this.getUserPMGByCert(this.mode, 1);
                  break;
                default:
                  this.getNumisPMGByCert(1);
                  break;
              }
            }
          } else if (this.type == "user") {
            switch (this.bankNoteRemark) {
              case "pending":
                this.getWithUserPMG(this.bankNoteRemark, 1);
                break;
              case "sent":
                this.getWithUserPMG(this.bankNoteRemark, 1);
                break;
              case "returned":
                this.getWithUserPMG(this.bankNoteRemark, 1);
                break;
            }
          } else {
            this.getWithNumisartPMG(1);
          }
        },
        deep: true,
      },
      returnedCurrentPage: {
        handler() {
          if (this.status == "address") {
            this.getUserPMGByAddress(this.mode, this.pendingCurrentPage);
          } else if (this.status == "cert") {
            this.getUserPMGByCert(this.mode, this.pendingCurrentPage);
          } else {
            this.getWithUserPMG("returned", this.returnedCurrentPage);
          }
        },
        deep: true,
      },
      pendingCurrentPage: {
        handler() {
          if (this.status == "address") {
            this.getUserPMGByAddress(this.mode, this.pendingCurrentPage);
          } else if (this.status == "cert") {
            this.getUserPMGByCert(this.mode, this.pendingCurrentPage);
          } else {
            this.getWithUserPMG("pending", this.pendingCurrentPage);
          }
        },
        deep: true,
      },
      sentCurrentPage: {
        handler() {
          if (this.status == "address") {
            this.getUserPMGByAddress(this.mode, this.sentCurrentPage);
          } else if (this.status == "cert") {
            this.getUserPMGByCert(this.mode, this.sentCurrentPage);
          } else {
            this.getWithUserPMG("sent", this.sentCurrentPage);
          }
        },
        deep: true,
      },
      withNumisartCurrentPage: {
        handler() {
          if (this.status == "address") {
            this.getNumisPMGByAddress(this.withNumisartCurrentPage);
          } else if (this.status == "cert") {
            this.getNumisPMGByCert(this.withNumisartCurrentPage);
          } else {
            this.getWithNumisartPMG(this.withNumisartCurrentPage);
          }
        },
        deep: true,
      },
    },
    methods: {
      reloadPage() {
        window.location.reload();
      },
      getWithUserPMG(remark, currentPage) {
        this.type = "user";
        if (remark) {
          this.bankNoteRemark = remark;
        }
        try {
          axios({
            method: "get",
            url:
              this.url +
              `api/admin/find/allReturns?pageSize=${this.perPage}&page=${currentPage}&remarks=${this.bankNoteRemark}`,
            // headers: { ...data.getHeaders() },
          }).then((response) => {
            if (this.bankNoteRemark == "sent") {
              this.sentPMG = response.data.results;
              this.sentTotalRows = response.data.metadata.data;
            } else if (this.bankNoteRemark == "pending") {
              this.pendingTotalRows = response.data.metadata.data;
              this.pendingPMG = response.data.results;
            } else {
              this.returnedPMG = response.data.results;
              this.returnedTotalRows = response.data.metadata.data;
            }
          });
        } catch (e) {
          console.log("error", e.message);
        }
      },
      getWithNumisartPMG(currentPage) {
        this.type = "numisart";
        this.status = "numisart";
        this.bankNoteRemark = "with Numisart";
        try {
          axios({
            method: "get",
            url:
              this.url +
              `api/admin/pmg/banknotes?status=holding&pageSize=${this.perPage}&page=${currentPage}`,
            // headers: { ...data.getHeaders() },
          }).then((response) => {
            this.withNumisartPMG = response.data.results;
            this.withNumisartTotalRows = response.data.metadata.data;
          });
        } catch (e) {
          console.log("error", e.message);
        }
      },
      getAdmin() {
        if (this.contract) {
          try {
            axios({
              method: "get",
              url: this.url + "api/admin/",
              // headers: { ...data.getHeaders() },
            }).then((response) => {
              response.data.map((data) => {
                if (data.status === true) {
                  this.authorisedAddress.push(data);
                }
              });
              // this.authorisedAddress = response.data;
              // this.authorisedAddress.push({ address: this.ownerAddress });
            });
          } catch (e) {
            console.log("error", e.message);
          }
        }
      },
      getDate(date) {
        return moment(date).format("DD/MM/YYYY hh:mm:ss a");
      },
      showAlert(type, title, state) {
        this.$swal
          .fire({
            position: "center",
            icon: type,
            title: title,
            showConfirmButton: true,
            backdrop: true,
            allowOutsideClick: false,
          })
          .then((result) => {
            if (result.isConfirmed && state == 0) {
              this.reloadPage();
            }
          });
      },
      showLoading() {
        this.$swal.fire({
          position: "center",
          title: "Processing...",
          showConfirmButton: false,
          backdrop: true,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
        });
      },
      async updatePMGStatus(id, token, address, status) {
        let tokenID = [token];
        let contractAddress = [address];
        let success = false;
        let contractSuccess;
        if (status == "returned") {
          this.showLoading();
          let marketContract = new ethers.Contract(
            this.marketAddress,
            this.marketABI,
            provider.getSigner()
          );
          try {
            const updateAction = await marketContract.adminUpdatePMG(
              contractAddress,
              tokenID,
              [1]
            );
            const receipt = await provider.waitForTransaction(
              updateAction.hash
            );

            if (!receipt) {
              contractSuccess = false;
              this.showAlert("error", "Contract Error, update failed.", 2);
            } else {
              contractSuccess = true;
              this.showAlert("success", "Contract Update Success.", 1);
            }

            if (contractSuccess == true) {
              for (let i = 0; i < this.authorisedAddress.length; i++) {
                if (this.authorisedAddress[i].address == this.signer) {
                  success = true;

                  try {
                    axios({
                      method: "post",
                      url:
                        this.url +
                        `api/admin/update/returnStatus?return_id=${id}&status=${status}`,
                      headers: {
                        Authorization: "Basic YWRtaW46TExEUE54X1pxOTQ/QWEhUw==",
                      },
                    }).then(() => {
                      this.showAlert("success", "Success Update", 0);
                    });
                  } catch (e) {
                    this.showAlert("error", "Update Failed.", 1);
                    console.log("error", e.message);
                  }
                }
              }
            }
          } catch (e) {
            this.showAlert("error", "Update Failed.", 2);
          }
        } else {
          for (let i = 0; i < this.authorisedAddress.length; i++) {
            if (this.authorisedAddress[i].address == this.signer) {
              success = true;
              this.showLoading();
              try {
                axios({
                  method: "post",
                  url:
                    this.url +
                    `api/admin/update/returnStatus?return_id=${id}&status=${status}`,
                  headers: {
                    Authorization: "Basic YWRtaW46TExEUE54X1pxOTQ/QWEhUw==",
                  },
                }).then(() => {
                  this.showAlert("success", "Success Update", 0);
                });
              } catch (e) {
                this.showAlert("error", "Update Failed.", 1);
                console.log("error", e.message);
              }
            }
          }
        }
        if (success == false) {
          this.showAlert("error", "Not authorised for this action.", 1);
        }
      },
      searchWithNumisart() {
        this.type = "search";
        this.withNumisartCurrentPage = 1;
        if (this.searchWord) {
          if (ethers.utils.isAddress(this.searchWord)) {
            this.getNumisPMGByAddress(1);
          } else {
            this.getNumisPMGByCert(1);
          }
        } else {
          this.showAlert("error", "Cannot be empty.", 1);
        }
      },
      getNumisPMGByAddress(currentPage) {
        this.status = "address";
        try {
          axios({
            method: "get",
            url:
              this.url +
              `api/admin/pmg/withNumisAddress/${this.searchWord}?pageSize=${this.perPage}&page=${currentPage}`,
            // headers: { ...data.getHeaders() },
          }).then((response) => {
            this.withNumisartPMG = response.data.results;
            this.withNumisartTotalRows = response.data.metadata.data;
          });
        } catch (e) {
          console.log("error", e.message);
        }
      },
      getNumisPMGByCert(currentPage) {
        this.state = "cert";
        try {
          axios({
            method: "get",
            url:
              this.url +
              `api/admin/pmg/withNumisCert/${this.searchWord}?pageSize=${this.perPage}&page=${currentPage}`,
            // headers: { ...data.getHeaders() },
          }).then((response) => {
            this.withNumisartPMG = response.data.results;
            this.withNumisartTotalRows = response.data.metadata.data;
          });
        } catch (e) {
          console.log("error", e.message);
        }
      },
      searchWithUser(table) {
        this.type = "search";
        this.currentPage = 1;
        this.pendingCurrentPage = 1;
        this.returnedCurrentPage = 1;
        this.sentCurrentPage = 1;
        if (this.searchWord) {
          if (ethers.utils.isAddress(this.searchWord)) {
            this.getUserPMGByAddress(table, 1);
          } else {
            this.getUserPMGByCert(table, 1);
          }
        } else {
          this.showAlert("error", "Cannot be empty.", 1);
        }
      },
      getUserPMGByAddress(table, currentPage) {
        this.status = "address";
        this.mode = table;
        if (table == "pending") {
          try {
            axios({
              method: "get",
              url:
                this.url +
                `api/admin/withUser/address?pageSize=${this.perPage}&page=${currentPage}&wallet_address=${this.searchWord}&remark=${table}`,
              // headers: { ...data.getHeaders() },
            }).then((response) => {
              this.pendingPMG = response.data.results;
              this.pendingTotalRows = response.data.metadata.data;
            });
          } catch (e) {
            console.log("error", e.message);
          }
        } else if (table == "sent") {
          this.sentCurrentPage = this.currentPage;
          try {
            axios({
              method: "get",
              url:
                this.url +
                `api/admin/withUser/address?pageSize=${this.perPage}&page=${this.currentPage}&wallet_address=${this.searchWord}&remark=${table}`,
              // headers: { ...data.getHeaders() },
            }).then((response) => {
              this.sentPMG = response.data.results;
              this.sentTotalRows = response.data.metadata.data;
            });
          } catch (e) {
            console.log("error", e.message);
          }
        } else {
          this.returnedCurrentPage = this.currentPage;
          try {
            axios({
              method: "get",
              url:
                this.url +
                `api/admin/withUser/address?pageSize=${this.perPage}&page=${this.currentPage}&wallet_address=${this.searchWord}&remark=${table}`,
              // headers: { ...data.getHeaders() },
            }).then((response) => {
              this.returnedPMG = response.data.results;
              this.returnedTotalRows = response.data.metadata.data;
            });
          } catch (e) {
            console.log("error", e.message);
          }
        }
      },
      getUserPMGByCert(table, currentPage) {
        this.status = "cert";
        this.mode = table;

        if (table == "pending") {
          try {
            axios({
              method: "get",
              url:
                this.url +
                `api/admin/withUser/cert?pageSize=${this.perPage}&page=${currentPage}&pmg_cert=${this.searchWord}&remark=${table}`,
              // headers: { ...data.getHeaders() },
            }).then((response) => {
              this.pendingPMG = response.data.results;
              this.pendingTotalRows = response.data.metadata.data;
            });
          } catch (e) {
            console.log("error", e.message);
          }
        } else if (table == "sent") {
          this.sentCurrentPage = this.currentPage;
          try {
            axios({
              method: "get",
              url:
                this.url +
                `api/admin/withUser/cert?pageSize=${this.perPage}&page=${this.currentPage}&pmg_cert=${this.searchWord}&remark=${table}`,
              // headers: { ...data.getHeaders() },
            }).then((response) => {
              this.sentPMG = response.data.results;
              this.sentTotalRows = response.data.metadata.data;
              this.currentPage++;
            });
          } catch (e) {
            console.log("error", e.message);
          }
        } else {
          this.returnedCurrentPage = this.currentPage;
          try {
            axios({
              method: "get",
              url:
                this.url +
                `api/admin/withUser/cert?pageSize=${this.perPage}&page=${this.currentPage}&pmg_cert=${this.searchWord}&remark=${table}`,
              // headers: { ...data.getHeaders() },
            }).then((response) => {
              this.returnedPMG = response.data.results;
              this.returnedTotalRows = response.data.metadata.data;
              this.currentPage++;
            });
          } catch (e) {
            console.log("error", e.message);
          }
        }
      },
    },
  };
</script>

<style>
  .button-section {
    display: flex;
    justify-content: center;
  }

  .return {
    justify-content: center;
  }

  .button-section > button {
    padding: 5px 20px !important;
    margin: 0px 3px;
  }

  .pmg-cert {
    margin: 0px 20px;
    width: 100%;
    text-align: start;
  }

  .user-info {
    display: flex;
    flex-direction: column;
    text-align: start;
  }

  .address-field {
    line-break: anywhere;
  }

  .spinner {
    position: absolute;
    width: 100%;
    background: black;
    height: 100%;
    opacity: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
  }
</style>
